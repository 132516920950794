import React, { Component } from 'react'
import HashLoader from "react-spinners/HashLoader";
import './Loader.css';

class Loader extends Component {
    
    render() {
        const css = {
            'margin': '0 auto',
            'z-index': '101',
            'top': '30%',
        }

        return (
            <div className='loader-container text-center'>
                <HashLoader
                    size={150}
                    css={css}
                    //size={"150px"} this also works
                    color={"#123abc"}
                    loading={this.props.loading}
                />
            </div>
        )
    }
}

Loader.defaultProps = {
    loading: false,
}

export default Loader;