import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown/Dropdown';

var HtmlToReactParser = require('html-to-react').Parser;

class MemberFormOnlineStep2 extends Component {
  state = {
    datasource: {
      province: [],
    },
    company_profile: { },
  }

  onChange = e => {
    const { name, value } = e.target;
    
    let data = { ...this.props.data };
    data[name] = value;

    if(name === 'receipt_address_type' && value === 'company'){
      const company_profile = this.state.company_profile;

      data.receipt_name = company_profile.receipt_name;
      data.receipt_address = company_profile.receipt_address;
      data.receipt_district = company_profile.receipt_district;
      data.receipt_sub_district = company_profile.receipt_sub_district;
      data.receipt_zip_code = company_profile.receipt_zip_code;
      data.receipt_province_id = company_profile.receipt_province_id;
    }

    this.props.onChange(data);
  }

  onDDLChange = obj => {
		let data = { ...this.props.data, ...obj }
		
    this.props.onChange(data);
	}

  getAPIDatasource = () => {
		const api_url = 'datasource';
		const params = {
		  tables: [
			  { name: 'province' }
		  ]
		}
	
		const promise = axios.post(api_url, params).then(res => {
		  const datasource = {
        province: res.data.province.map(item => { return { 'key': item.id, 'label': item.name } }),
		  }
	
		  return datasource;
		}).catch(res => {
		  console.log('error', res);
		});
	
		return promise;
  }	
  
  getCompanyProfile = () => {
    const promise = axios.get('company_profile').then(res => {
      const company_profile = res.data;

      return company_profile;
    });

    return promise;
  }

  componentDidMount = () => {
    this.getAPIDatasource().then( result => {
			this.setState({
				datasource: result,
			});
    });
    

    this.getCompanyProfile().then( result => {
      this.setState({
				company_profile: result,
			});
    });
  }

  render(){
    let receipt_address, name_and_identity_number_address_element;
    let data = this.props.data;

    data.document_name = data.document_name || '';
    data.document_address = data.document_address || '';
    data.document_district = data.document_district || '';
    data.document_sub_district = data.document_sub_district || '';
    data.document_province_id = data.document_province_id || '';
    data.document_zip_code = data.document_zip_code || '';
    data.receipt_name = data.receipt_name || '';
    data.receipt_identity_no = data.receipt_identity_no || '';
    data.receipt_address = data.receipt_address || '';
    data.receipt_district = data.receipt_district || '';
    data.receipt_sub_district = data.receipt_sub_district || '';
    data.receipt_province_id = data.receipt_province_id || '';
    data.receipt_zip_code = data.receipt_zip_code || '';

    if(data.delivery_document_type !== 'receipt'){
      receipt_address = (
        <div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black field-required" htmlFor="document_name">ชื่อผู้รับ</label>
              <input type="text" id="document_name" name='document_name' value={data.document_name} onChange={this.onChange} className="form-control" required />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black field-required" htmlFor="document_address">ที่อยู่</label>
              <input type="text" id="document_address" name='document_address' value={data.document_address} onChange={this.onChange} className="form-control" required />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-6 mb-3 mb-md-0">
              <label className="text-black field-required" htmlFor="document_sub_district">แขวง/ตำบล</label>
              <input type="text" id="document_sub_district" name='document_sub_district' value={data.document_sub_district} onChange={this.onChange} className="form-control" required />
            </div>
            <div className="col-md-6 mb-3 mb-md-0">
              <label className="text-black field-required" htmlFor="document_district">เขต/อำเภอ</label>
              <input type="text" id="document_district" name='document_district' value={data.document_district} onChange={this.onChange} className="form-control" required />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-6 mb-3 mb-md-0">
              <label className="text-black field-required" htmlFor="document_province_id">จังหวัด</label>
              <Dropdown
                  datasource={this.state.datasource.province}
                  name='document_province_id'
                  value={data.document_province_id}
                  onChange={this.onDDLChange}
                  required={true}
                  placeholder='กรุณาเลือก'>
              </Dropdown>
            </div>
            <div className="col-md-6 mb-3 mb-md-0">
              <label className="text-black field-required" htmlFor="document_zip_code">รหัสไปรษณีย์</label>
              <input type="text" id="document_zip_code" name='document_zip_code' value={data.document_zip_code} onChange={this.onChange} className="form-control" />
            </div>
          </div>
        </div>
      )
    }

    if(data.receipt_address_type === 'company'){
      name_and_identity_number_address_element = (
        <div className="row form-group">
          <div className="col-md-6 mb-3 mb-md-0">
            <label className="text-black" htmlFor="receipt_name">ชื่อในการออกใบเสร็จ</label>
            <input type="text" id="receipt_name" name='receipt_name' className="form-control" value={data.receipt_name} onChange={this.onChange} />
          </div>
          <div className="col-md-6 mb-3 mb-md-0">
            <label className="text-black" htmlFor="receipt_identity_no">เลขประจำตัวผู้เสียภาษี</label>
            <input type="text" id="receipt_identity_no" name='receipt_identity_no' className="form-control" value={data.receipt_identity_no} onChange={this.onChange} />
          </div>
        </div>
      )
    }

    return (
      <div className="tab-pane" role="tabpanel" id="step2">
          <h3>ที่อยู่ในการออกใบเสร็จ</h3>
          <div className='text-left p-5'>
            <div className="row form-group">
              <div className="col-md-12 mb-3 mb-md-0">
                <label className="text-black mr-2" htmlFor="receipt_address_type">การออกใบเสร็จ</label>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input onChange={this.onChange} checked={(data.receipt_address_type === 'personal' ? true : false)} required="required" type="radio" name="receipt_address_type" className="form-check-input" value="personal" />ออกใบเสร็จในนามบุคคล
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input onChange={this.onChange} checked={(data.receipt_address_type === 'company' ? true : false)} required="required" type="radio" name="receipt_address_type" className="form-check-input" value="company" />ออกใบเสร็จในนาม รพ./หน่วยงาน/บริษัท
                  </label>
                </div>
              </div>
            </div>
            {name_and_identity_number_address_element}
            <div className="row form-group">
              <div className="col-md-12 mb-3 mb-md-0">
                <label className="text-black field-required" htmlFor="receipt_address">ที่อยู่</label>
                <input type="text" id="receipt_address" name='receipt_address' className="form-control" value={data.receipt_address} onChange={this.onChange} required />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-6 mb-3 mb-md-0">
                <label className="text-black field-required" htmlFor="receipt_sub_district">แขวง/ตำบล</label>
                <input type="text" id="receipt_sub_district" name='receipt_sub_district' className="form-control" value={data.receipt_sub_district} onChange={this.onChange} required/>
              </div>
              <div className="col-md-6 mb-3 mb-md-0">
                <label className="text-black field-required" htmlFor="receipt_district">เขต/อำเภอ</label>
                <input type="text" id="receipt_district" name='receipt_district' className="form-control" value={data.receipt_district} onChange={this.onChange} required/>
              </div>
              
            </div>
            <div className="row form-group">
              <div className="col-md-6 mb-3 mb-md-0">
                <label className="text-black field-required" htmlFor="receipt_province_id">จังหวัด</label>
                <Dropdown
                    datasource={this.state.datasource.province}
                    name='receipt_province_id'
                    value={data.receipt_province_id}
                    onChange={this.onDDLChange}
                    required={true}
                    placeholder='กรุณาเลือก'>
                </Dropdown>
              </div>
              <div className="col-md-6 mb-3 mb-md-0">
                <label className="text-black field-required" htmlFor="receipt_zip_code">รหัสไปรษณีย์</label>
                <input type="text" id="receipt_zip_code" name='receipt_zip_code' className="form-control" value={data.receipt_zip_code} onChange={this.onChange} required/>
              </div>
            </div>
          </div>
          <hr/>
          <h3>ที่อยู่ในการจัดส่งเอกสาร</h3>
          {/* <p><small className='text-mute'>ที่อยู่ที่ใช้ในการจัดส่งเอกสาร</small></p> */}
          <div className='text-left p-5'>
            <div className="row form-group">
              <div className="col-md-12 mb-3 mb-md-0">
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input type="radio" onChange={this.onChange} checked={(data.delivery_document_type === 'receipt' ? true : false)} name="delivery_document_type" className="form-check-input" value="receipt" />ใช้ที่อยู่ในการออกใบเสร็จ
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input type="radio" onChange={this.onChange} checked={(data.delivery_document_type === 'other' ? true : false)} name="delivery_document_type" className="form-check-input" value="other" />ใช้ที่อยู่อื่น
                  </label>
                </div>
              </div>
            </div>
            {receipt_address}
          </div>
      </div>
    )
  }
}

MemberFormOnlineStep2.defaultProps = {
	data: {
    receipt_address_type: 'personal',
    receipt_name: '',
    receipt_identity_no: '',
    receipt_address: '',
    receipt_district: '',
    receipt_sub_district: '',
    receipt_zip_code: '',
    receipt_province_id: '',

    document_name: '',
    document_address: '',
    document_district: '',
    document_sub_district: '',
    document_province_id: '',
    document_zip_code: '',
    delivery_document_type: 'receipt',
  },
}

export default MemberFormOnlineStep2