import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import MemberFormStep1 from "./MemberFormStep1";
import MemberFormStep2 from "./MemberFormStep2";
import MemberFormStep3 from "./MemberFormStep3";
import { Alert, Services } from "../../Service/Services";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
const $ = window.$;

var HtmlToReactParser = require("html-to-react").Parser;

class MemberForm extends Component {
  state = {
    redirect: false,
    loading: true,
    errors: [],
    step1: [],
    step2: {
      receipt_user_delivery_profile_id: null,
      receipt_address_type: "personal",
      receipt_name: "",
      receipt_identity_no: "",
      receipt_address: "",
      receipt_district: "",
      receipt_sub_district: "",
      receipt_zip_code: "",
      receipt_province_id: "",
      receipt_province_name: "",
      receipt_branch_code: "",
      receipt_branch_name: "",

      document_name: "",
      document_address: "",
      document_district: "",
      document_sub_district: "",
      document_province_id: "",
      document_zip_code: "",
      delivery_document_type: "receipt",
    },
    step3: {
      allow_save: false,
    },
    company_profile: {},
    datasource: {
      career: [],
      province: [],
      prefix_name: [],
    },
    is_printed: false,
    editable: true,
    url: {
      invoice: axios.defaults.baseURL + "/invoice/",
      receipt: axios.defaults.baseURL + "/receiptsample",
    },
    is_early_bird: false,
    url_preview_receipt: "",
    enable_step2: false,
    enable_step3: false,
    resource_url_preview_receipt: {
      step1: [],
      step2: {},
    },
  };

  onChangeStep1 = (data) => {
    this.setState({
      step1: data,
      enable_step2: data.length > 0,
      enable_step3:
        data.length > 0 &&
        this.state.step2.receipt_user_delivery_profile_id !== null,
    });
  };

  onChangeStep2 = (data) => {
    this.setState({
      step2: data,
      enable_step3: data.receipt_user_delivery_profile_id !== null,
    });
  };

  onChangeStep3 = (data) => {
    this.setState({
      step3: data,
    });
  };

  componentDidMount = () => {
    $(document).ready(() => {
      //Initialize tooltips
      $(".nav-tabs > li a[title]").tooltip();

      //Wizard
      $('.wizard .nav-tabs li a[data-toggle="tab"]').on("show.bs.tab", (e) => {
        var $target = $(e.target);

        if ($target.parent().hasClass("disabled")) {
          return false;
        } else {
          $(".wizard .nav-tabs li.active").removeClass("active");
          $target.parent().addClass("active");
          if ($target.parent().index() === 2) this.loadUrlReceipt();
        }
      });
    });

    const id = this.props.match.params.id;
    const is_early_bird =
      window.location.href.toLowerCase().indexOf("earlybird") !== -1;

    this.getAPIDatasource().then((datasource) => {
      if (id !== undefined) {
        const action = this.props.match.params.action;

        axios.get("member/" + id).then((res) => {
          const data = res.data;

          const step1 = data.members.map((item, key) => {
            const prefix =
              item.prefix_id === null ? item.prefix_text : item.prefix_id;
            const career =
              item.career_id === null ? item.career_text : item.career_id;

            return {
              id: item.id,
              prefix_id: prefix,
              first_name: item.first_name,
              last_name: item.last_name,
              identity_number: item.identity_number ? item.identity_number : "",
              email: item.email,
              career_id: career,
              career_number: item.career_number ? item.career_number : "",
              nurse_number: item.nurse_number ? item.nurse_number : "",
              row_index: key,
              // avatar: item.avatar,
            };
          });

          const step2 = {
            receipt_user_delivery_profile_id:
              data.receipt_user_delivery_profile_id,
            receipt_address_type: data.receipt_address_type,
            receipt_name: data.receipt_name,
            receipt_identity_no: data.receipt_identity_no,
            receipt_address: data.receipt_address,
            receipt_district: data.receipt_district,
            receipt_sub_district: data.receipt_sub_district,
            receipt_zip_code: data.receipt_zip_code,
            receipt_province_id: data.receipt_province_id,
            receipt_branch_code: data.receipt_branch_code,
            receipt_branch_name: data.receipt_branch_name,

            document_name: data.document_name,
            document_address: data.document_address,
            document_district: data.document_district,
            document_sub_district: data.document_sub_district,
            document_province_id: data.document_province_id,
            document_zip_code: data.document_zip_code,
            delivery_document_type: data.delivery_document_type,
          };

          const is_printed = data.payments.length > 0;

          this.setState({
            step1,
            step2,
            loading: false,
            is_printed,
            editable: action === "view" || is_printed === true ? false : true,
            is_early_bird,
            enable_step2: true,
            enable_step3: true,
            datasource: datasource,
          });
        });
      } else {
        this.setState({
          loading: false,
          is_early_bird,
          datasource: datasource,
        });
      }
    });
  };

  getAPIDatasource = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "career" }, { name: "prefix_name" }],
    };

    const promise = axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          career: res.data.career.map((item) => {
            return { key: item.id, label: item.name, code: item.code };
          }),
          prefix_name: res.data.prefix_name.map((item) => {
            return { key: item.id, label: item.name };
          }),
        };

        return datasource;
      })
      .catch((res) => {
        console.log("error", res);
      });

    return promise;
  };

  // nextTab = (elem) => {
  //   const index = $(elem).index() + 1;
  //   $(elem)
  //     .next()
  //     .find('a[data-toggle="tab"]')
  //     .trigger("click", { is_button: true });

  //   this.toggleButton(index);
  // };

  // prevTab = (elem) => {
  //   const index = $(elem).index() - 1;
  //   $(elem)
  //     .prev()
  //     .find('a[data-toggle="tab"]')
  //     .trigger("click", { is_button: true });

  //   this.toggleButton(index);
  // };

  toggleButton = (step) => {
    $(`.wizard .nav-tabs li a[data-toggle="tab"]:eq(${step - 1})`).trigger(
      "click"
    );
    // if (index <= 0) {
    //   $("#btn_prev").addClass("d-none");
    //   $("#btn_submit").addClass("d-none");
    //   $("#btn_invoice").addClass("d-none");
    //   $("#btn_receipt").addClass("d-none");
    //   $("#btn_next").removeClass("d-none");
    // } else if (index >= 2) {
    //   $("#btn_next").addClass("d-none");
    //   $("#btn_prev").removeClass("d-none");
    //   $("#btn_submit").removeClass("d-none");
    //   $("#btn_invoice").removeClass("d-none");
    //   $("#btn_receipt").removeClass("d-none");
    // } else {
    //   $("#btn_submit").addClass("d-none");
    //   $("#btn_invoice").addClass("d-none");
    //   $("#btn_receipt").addClass("d-none");
    //   $("#btn_next").removeClass("d-none");
    //   $("#btn_prev").removeClass("d-none");
    // }
  };

  onSubmit = (e, is_print) => {
    e.preventDefault();

    if (this.state.step3.allow_save === false) {
      return Alert.error("กรุณาติ๊กยินยอมเงื่อนไข");
    }

    if (is_print === true) {
      Alert.confirm(
        "คุณแน่ใจไหมที่จะพิมพ์ใบแจ้งชำระเงิน เมื่อพิมพ์แล้วจะไม่สามารถแก้ไขข้อมูลผู้เข้าร่วมงานได้"
      ).then((res) => {
        if (res === true) {
          this.save(is_print);
        }
      });
    } else {
      this.save(is_print);
    }
  };

  save = (is_print) => {
    if (this.state.editable === false) return;

    const id = this.props.match.params.id;

    this.setState({ loading: true });
    const acceptNews = this.props.location.search.includes("news");

    let params = {
      params: {
        is_online: "0",
        is_early_bird: this.state.is_early_bird ? "1" : "0",
        members: JSON.stringify(this.state.step1),
        document: JSON.stringify(this.state.step2),
        id,
        acceptNews: acceptNews ? "1" : "0",
      },
      url: "/member",
    };

    Services.submitRequestWithFile(params)
      .then((res) => {
        if (is_print === true) {
          const url_invoice = this.state.url.invoice + res.data.id;
          axios.get(url_invoice, { responseType: "blob" }).then((res) => {
            var url = window.URL.createObjectURL(res.request.response);

            window.open(url, "_blank");

            this.setState({ loading: false, redirect: true });
          });
        } else {
          this.setState({ loading: false, redirect: true });
        }
      })
      .catch((errors) => {
        this.setState({ errors, loading: false });
      });
  };

  onLoading = (loading) => {
    this.setState({ loading });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = "/member/thankyou";

      return <Redirect to={url} />;
    }

    return;
  };

  loadUrlReceipt = () => {
    const url_receipt = this.state.url.receipt;
    const params = {
      step1: this.state.step1.map((item) => {
        return {
          prefix_id: item.prefix_id,
          first_name: item.first_name,
          last_name: item.last_name,
          identity_number: item.identity_number,
          email: item.email,
          career_id: item.career_id,
          career_number: item.career_number,
          nurse_number: item.nurse_number,
        };
      }),
      step2: this.state.step2,
    };

    if (
      JSON.stringify(params) ===
      JSON.stringify(this.state.resource_url_preview_receipt)
    )
      return;

    this.setState({ url_preview_receipt: null });

    axios
      .post(url_receipt, params, {
        responseType: "blob",
      })
      .then((res) => {
        var url = window.URL.createObjectURL(res.request.response);
        this.setState({
          url_preview_receipt: url,
          resource_url_preview_receipt: params,
        });
      });
  };

  render() {
    let error;

    if (this.state.errors.length > 0) {
      let errors = this.state.errors.map((e) => (
        <li>
          <i className="fa fa-close"></i> {e}
        </li>
      ));

      error = (
        <div className="alert alert-danger text-left">
          <ul className="list-unstyled">{errors}</ul>
        </div>
      );
    }

    const redirect = this.getRedirect();
    const id = this.props.match.params.id;

    let member_form_step1, member_form_step2, member_form_step3;

    member_form_step1 = (
      <MemberFormStep1
        onLoading={this.onLoading}
        data={this.state.step1}
        editable={this.state.editable}
        onChange={this.onChangeStep1}
        changeStep={(new_step) => this.toggleButton(new_step)}
        datasource={this.state.datasource}
        id={id}
      ></MemberFormStep1>
    );
    member_form_step2 = (
      <MemberFormStep2
        editable={this.state.editable}
        data={this.state.step2}
        onChange={this.onChangeStep2}
        company_profile={this.state.company_profile}
        changeStep={(new_step) => this.toggleButton(new_step)}
        datasource={this.state.datasource}
        id={id}
      ></MemberFormStep2>
    );
    member_form_step3 = (
      <MemberFormStep3
        editable={this.state.editable}
        step1={this.state.step1}
        step2={this.state.step2}
        data={this.state.step3}
        onChange={this.onChangeStep3}
        url_preview_receipt={this.state.url_preview_receipt}
        onSubmit={this.onSubmit}
        changeStep={(new_step) => this.toggleButton(new_step)}
        datasource={this.state.datasource}
        id={id}
      ></MemberFormStep3>
    );

    return (
      <FrontendBoxContainer
        loading={this.state.loading}
        title="ลงทะเบียนเข้าร่วมงาน"
        subtitle="ลงทะเบียนผู้ที่เข้าร่วมงานประชุม"
      >
        {redirect}
        {error}
        <div className="wizard">
          <div className="wizard-inner">
            <div className="connecting-line"></div>
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="enable active">
                <a
                  href="#step1"
                  data-toggle="tab"
                  aria-controls="step1"
                  role="tab"
                  title="Step 1"
                >
                  <span className="round-tab">
                    <i className="fa fa-users"></i>
                  </span>
                </a>
              </li>
              <li
                role="presentation"
                className={this.state.enable_step2 ? "enable" : "disabled"}
              >
                <a
                  href={this.state.enable_step2 ? "#step2" : "#"}
                  data-toggle="tab"
                  aria-controls="step2"
                  role="tab"
                  title="Step 2"
                >
                  <span className="round-tab">
                    <i className="fa fa-building"></i>
                  </span>
                </a>
              </li>
              <li
                role="presentation"
                className={this.state.enable_step3 ? "enable" : "disabled"}
              >
                <a
                  href={this.state.enable_step3 > 0 ? "#complete" : "#"}
                  data-toggle="tab"
                  aria-controls="complete"
                  role="tab"
                  title="Complete"
                >
                  <span className="round-tab">
                    <i className="fa fa-check"></i>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            {member_form_step1}
            {member_form_step2}
            {member_form_step3}
            <div className="clearfix"></div>
          </div>
          <div className="mt-5">
            <a href="/member" className="btn btn-link">
              <i className="fa fa-arrow-left"></i>{" "}
              กลับไปหน้าลงทะเบียนเข้าร่วมงาน
            </a>
          </div>
        </div>
      </FrontendBoxContainer>
    );
  }
}

export default MemberForm;
