import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
var HtmlToReactParser = require('html-to-react').Parser;

class Banner extends Component {
  render() {
    const banners = this.props.data.map((item, key) => {
      let img;

      if(item.link){
        img = (<a href={item.link} target='_blank'><img src={item.url} alt="Banner" className="d-block w-100" /></a>)
      }else{
        img = (<img src={item.url} alt="Banner" className="d-block w-100" />)
      }

      return (
        <div className={ ["carousel-item", (key === 0 ? 'active' : '')].join(' ') } key={key}>{img}</div>
      );
    });

    return (
      <div id="carouselExampleControls" data-ride="carousel" className="carousel slide ">
        <div className="carousel-inner">
          {banners}
        </div> 
        <a href="#carouselExampleControls" role="button" data-slide="prev" className="carousel-control-prev">
          <span aria-hidden="true" className="carousel-control-prev-icon"></span> 
          <span className="sr-only">Previous</span>
        </a>
        <a href="#carouselExampleControls" role="button" data-slide="next" className="carousel-control-next">
          <span aria-hidden="true" className="carousel-control-next-icon"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
    )
  }
}

Banner.defaultProps = {
  data: [],
}

export default Banner