import axios from "../axios";

const getSiteInfo = () => {
  const promise = new Promise((resolve, reject) => {
    const url = `siteinfo`;
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch((res) => reject(res));
  });

  return promise;
};

export default {
  getSiteInfo,
};
