import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
var HtmlToReactParser = require('html-to-react').Parser;

class MemberFormOnlineStep3 extends Component {
  state = {
    datasource: {
      province: [],
    },
  }

  getAPIDatasource = () => {
		const api_url = 'datasource';
		const params = {
		  tables: [
			  { name: 'province' }
		  ]
		}
	
		const promise = axios.post(api_url, params).then(res => {
		  const datasource = {
        province: res.data.province.map(item => { return { 'key': item.id, 'label': item.name } }),
		  }
	
		  return datasource;
		}).catch(res => {
		  console.log('error', res);
		});
	
		return promise;
  }	

  getDocumentDeliveryAddress = () => {
    const step2 = this.props.step2;

    if(step2.delivery_document_type === 'receipt'){
      return this.getReceiptDeliveryAddress();
    }else{
      let document_delivery_address = '';

      if(step2.document_name !== undefined && step2.document_name !== null){
        document_delivery_address += (step2.document_name + ' ');
      }

      document_delivery_address += (step2.document_address + ' ');

      if(step2.document_sub_district){
        document_delivery_address += ('ตำบล' + step2.document_sub_district + ' ');
      }

      if(step2.document_district){
        document_delivery_address += ('อำเภอ' + step2.document_district + ' ');
      }

      if(step2.document_province_id){
        const province_name = this.state.datasource.province.find( f => f.key == step2.document_province_id).label;
        document_delivery_address += ('จังหวัด' + province_name + ' ');
      }

      document_delivery_address += (step2.document_zip_code);

      return document_delivery_address;
    }
  }

  getReceiptDeliveryAddress = () => {
    let receipt_delivery_address = '';

    const step2 = this.props.step2;

    if(step2.receipt_name !== undefined && step2.receipt_name !== null){
      receipt_delivery_address += (step2.receipt_name + ' ');
    }

    receipt_delivery_address += (step2.receipt_address + ' ');

    if(step2.receipt_sub_district){
      receipt_delivery_address += ('ตำบล' + step2.receipt_sub_district + ' ');
    }

    if(step2.receipt_district){
      receipt_delivery_address += ('อำเภอ' + step2.receipt_district + ' ');
    }

    if(step2.receipt_province_id){
      const province_name = this.state.datasource.province.find( f => f.key == step2.receipt_province_id).label;
      receipt_delivery_address += ('จังหวัด' + province_name + ' ');
    }

    receipt_delivery_address += (step2.receipt_zip_code);

    return receipt_delivery_address;
  }

  componentDidMount(){
    this.getAPIDatasource().then( result => {
			this.setState({
				datasource: result,
			});
    });
  }

  render() {
    let document_delivery_address = '';
    let receipt_delivery_address = '';

    if(this.state.datasource.province.length > 0){
      document_delivery_address = this.getDocumentDeliveryAddress();
      receipt_delivery_address = this.getReceiptDeliveryAddress();
    }

    const members = this.props.step1.map( (item, key) => {
      let theme_name = '';

      if(item.online_register_type){
        if(item.section_online_name){
          theme_name = (<span className='font-weight-bold'>{item.section_online_name}</span>);
        }else{
          theme_name = (<span className='font-weight-bold'>Free Theme</span>);
        }
      }

      return (
        <tr key={key}>
          <td>{item.first_name} {item.last_name}</td>
          <td className='text-center'>{item.identity_number}</td>
          <td className='text-center'>
						{theme_name}
					</td>
        </tr>
      )
    })

    return (
      <div className="tab-pane" role="tabpanel" id="complete">
          <h3>สรุปผล</h3>
          <div className='text-left p-5'>
            
              <strong>ที่อยู่ในการออกใบเสร็จ: </strong>{receipt_delivery_address}<br/>
              <strong>ที่อยู่ในการจัดส่งเอกสาร: </strong>{document_delivery_address}<br/>
              <strong>สมาชิก: </strong><br/>
              <table className='table'>
                <thead>
                  <tr>
                    <th className='text-center'>ชื่อ - นามสกุล</th>
                    <th className='text-center'>เลขที่บัตรประชาชน</th>
                    <th className='text-center'>Theme</th>
                  </tr>
                </thead>
                <tbody>
                  {members}
                </tbody>
              </table>
            
          </div>
      </div>
    )
  }
}

export default MemberFormOnlineStep3