import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../axios";
import { Redirect } from "react-router-dom";
import FrontendBoxContainer from "./../../components/FrontendBoxContainer/FrontendBoxContainer";
import Dropdown from "../../components/Dropdown/Dropdown";
import { Alert, Services } from "../Service/Services";
import Notify from "./../Landing/Home/Notify";

var HtmlToReactParser = require("html-to-react").Parser;

class Guest extends Component {
  state = {
    redirect: false,
    loading: true,
    errors: [],
    data: {
      id: "",
      code: "",
      guest_type_text: "",
      telephone: "",
      prefix_id: "",
      first_name: "",
      last_name: "",
      position: "",
      identity_no: "",
      career_id: "",
      career_number: "",
      company_id: "",
      job_identity_no: "",
      publish: false,
    },
    datasource: {
      prefix_name: [],
      career: [],
      company: [],
    },
  };

  retrieveData = (token) => {
    axios
      .get(`guest/${token}`)
      .then((res) => {
        const data = res.data;
        const loading = false;
        this.setState({
          loading,
          data: {
            code: data.code || "",
            id: data.id || "",
            guest_type_text: data.guest_type_text || "",
            telephone: data.telephone || "",
            prefix_id: data.prefix_id || data.prefix_text || "",
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            position: data.position || "",
            identity_no: data.identity_no || "",
            career_id: data.career_id || data.career_text || "",
            career_number: data.career_number || "",
            company_id: data.company_id || data.company_text || "",
            job_identity_no: data.job_identity_no || "",
            publish: data.publish,
          },
        });
      })
      .catch((res) => {
        if (res.status === 404) {
          this.setState({
            redirect: true,
          });
        }
      });
  };

  componentDidMount = () => {
    const token = this.props.match.params.token;

    const params = {
      tables: [
        { name: "prefix_name" },
        { name: "career" },
        { name: "company" },
      ],
    };

    axios.post("datasource", params).then((res) => {
      const prefix_name = res.data.prefix_name.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const career = res.data.career.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const company = res.data.company.map((item) => {
        return {
          key: item.id,
          label: item.name,
        };
      });

      const datasource = {
        prefix_name,
        career,
        company,
      };

      if (token !== undefined) {
        this.setState({ datasource });
        this.retrieveData(token);
      }
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  onDDLChange = (obj) => {
    let data = { ...this.state.data, ...obj };
    this.setState({ data });
  };

  onSubmit = (e) => {
    Alert.confirm(
      "เมื่อเปลี่ยนแปลงข้อมูลแล้ว จะไม่สามารถแก้ไขได้อีก",
      "คุณแน่ใจไหมที่จะเปลี่ยนแปลง"
    ).then((res) => {
      if (res === true) {
        const token = this.props.match.params.token;
        const params = this.state.data;
        const url = `guest`;
        let loading = true;

        this.setState({ loading });

        loading = false;

        Services.submitRequest({ params, url })
          .then((res) => {
            const token = this.props.match.params.token;
            this.retrieveData(token);
          })
          .catch((errors) => {
            this.setState({ errors, loading });
          });
      }
    });
  };

  onPrintCard = () => {
    this.setState({ loading: true });
    const id = this.state.data.id;

    const url = `guestprintcard/${id}`;

    axios.get(url, { responseType: "blob" }).then((res) => {
      let response = res.request.response;
      let url = window.URL.createObjectURL(response);
      window.open(url, "_blank");
      this.setState({ loading: false });
    });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = "/404";

      return <Redirect to={url} />;
    }

    return;
  };

  render() {
    const redirect = this.getRedirect();
    const disabled = this.state.data.publish === true;
    const notify_desc = `<div>
  		<p style='text-indent: 20px'>
  			ด้วยความห่วงใยในสุขภาพของผู้เข้าร่วมการประชุมวิชาการประจำปี ครั้งที่ 21 สรพ.จึงได้วางแนวทางการปฏิบัติตัวเพื่อป้องกันและลดความเสี่ยงในการแพร่กระจายเชื้อโรคต่างๆ อาทิ ไข้หวัดใหญ่ทุกสายพันธ์ โรคติดต่อทางเดินหายใจรวมทั้ง COVID-19 ในงานประชุม National HA Forum ครั้งที่ 21  โดยขอความร่วมมือผู้เข้าร่วมการประชุมทุกท่าน ให้เตรียมความพร้อมและปฏิบัติตน ดังต่อไปนี้ 
  		</p>
  		<p style='text-indent: 20px'>
  			งดการเข้าบริเวณงานประชุม ในผู้ที่มีอาการ หรือ ประวัติ ดังต่อไปนี้<br/>
  			<ul>
  				<li>มีไข้ มากกว่าหรือเท่ากับ 37.5 องศาเซลเซียล</li>
  				<li>มีอาการหวัด ไอ จาม น้ำมูก</li>
  				<li>ในช่วง 14 วันก่อนหน้านี้ ท่านให้การดูแลหรือสัมผัสใกล้ชิดกับผู้ป่วยปอดอักเสบรุนแรง</li>
  				<li>ผู้เดินทางจากประเทศที่มีการระบาดของโรคติดเชื้อไวรัสโคโรนา สายพันธุ์ 2019 ภายใน 14 วัน (จีน ฮ่องกง ไต้หวัน มาเก๊า ญี่ปุ่น เกาหลี มาเลเซีย สิงคโปร์ และ เวียดนาม)</li>
  			</ul>
  		</p>
  		<p style='text-indent: 20px'>
  		 	ในระหว่างที่มีการจัดประชุม ผู้เข้างานประชุมทุกท่านจะต้องผ่านจุดคัดกรอง เพื่อวัดไข้ โดย Themo scan  ณ บริเวณ ทางเข้าประตูทุกครั้งที่มีการเข้างาน  หากพบว่ามีไข้ มากกว่าหรือเท่ากับ 37.5 องศาเซลเซียส จะได้รับการซักประวัติ และสถาบันขอสงวนสิทธิ์ ไม่อนุญาตให้เข้างานประชุมโดยเด็ดขาด
  		</p>
  		<p style='text-indent: 20px'>
  			ท่านสามารถเปลี่ยนให้ผู้อื่นมาเข้าร่วมประชุมแทนได้  หรือติดต่อขอคืนเงินค่าลงทะเบียนได้เต็มราคา จากทางสถาบันภายในวันที่  16 มีนาคม 2563 ถึงวันที่ 30 เมษายน  2563 เท่านั้น  (หากเกินกว่าระยะเวลาที่กำหนด สรพ. ขอสงวนสิทธิ์ในการคืนเงิน) หรือสอบถามข้อมูลการคืนเงินได้  ที่ คุณอรพิน  เหล่าประทุม โทร 02 832 9416 /คุณปราณี บุทฤทธิ์  โทร 02 832 9420 /คุณนัจจพร สมจิตร โทร 02 832 9421
  		</p>
  	</div>`;

    const notify_data = {
      topic: "แนวทางการปฏิบัติสำหรับผู้เข้าประชุม The 21st HA National Forum",
      desc: notify_desc,
    };

    let btn_print;
    let btn_submit;

    if (this.state.loading === false) {
      if (this.state.data.publish === true) {
        btn_print = (
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={this.onPrintCard}
          >
            <i className="fa fa-print"></i> พิมพ์บัตรเข้าร่วมงาน
          </button>
        );
      } else {
        btn_submit = (
          <button
            type="button"
            className="btn btn-primary btn-block"
            disabled={disabled}
            onClick={this.onSubmit}
          >
            ส่งข้อมูล
          </button>
        );
      }
    }

    return (
      <FrontendBoxContainer loading={this.state.loading} title="แขกรับเชิญ">
        {redirect}
        {/* <Notify data={notify_data}/> */}
        <form action="#" className="p-5 bg-white text-left">
          <div className="mb-3">
            {btn_print}
            {btn_submit}
          </div>
          <h2 className="h4 text-black mb-5 text-center">แขกรับเชิญ</h2>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black">รหัสผู้ใช้งาน:</label>{" "}
              {this.state.data.code}
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black">ประเภท:</label>{" "}
              {this.state.data.guest_type_text}
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="company_id">
                สถานที่ทำงาน:
              </label>
              <Dropdown
                datasource={this.state.datasource.company}
                name="company_id"
                value={this.state.data.company_id}
                onChange={this.onDDLChange}
                allow_new_item={true}
                required={false}
                disabled={disabled}
                placeholder="กรุณาเลือก"
              ></Dropdown>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="prefix_id">
                คำนำหน้าชื่อ:
              </label>
              <Dropdown
                datasource={this.state.datasource.prefix_name}
                name="prefix_id"
                value={this.state.data.prefix_id}
                onChange={this.onDDLChange}
                allow_new_item={true}
                required={true}
                disabled={disabled}
                placeholder="กรุณาเลือก"
              ></Dropdown>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="first_name">
                ชื่อ:
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                onChange={this.onChange}
                disabled={disabled}
                value={this.state.data.first_name}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="last_name">
                นามสกุล:
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                onChange={this.onChange}
                disabled={disabled}
                value={this.state.data.last_name}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="identity_no">
                รหัสบัตรประชาชน:
              </label>
              <input
                type="text"
                name="identity_no"
                id="identity_no"
                onChange={this.onChange}
                disabled={disabled}
                value={this.state.data.identity_no}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="telephone">
                โทรศัพท์มือถือ:
              </label>
              <input
                type="text"
                name="telephone"
                id="telephone"
                onChange={this.onChange}
                disabled={disabled}
                value={this.state.data.telephone}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="position">
                ตำแหน่ง:
              </label>
              <input
                type="text"
                name="position"
                id="position"
                onChange={this.onChange}
                disabled={disabled}
                value={this.state.data.position}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="career_id">
                วิชาชีพ:
              </label>
              <Dropdown
                datasource={this.state.datasource.career}
                name="career_id"
                value={this.state.data.career_id}
                onChange={this.onDDLChange}
                allow_new_item={true}
                required={false}
                disabled={disabled}
                placeholder="กรุณาเลือก"
              ></Dropdown>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="career_number">
                เลขที่ประกอบวิชาชีพ:
              </label>
              <input
                type="text"
                name="career_number"
                id="career_number"
                onChange={this.onChange}
                disabled={disabled}
                value={this.state.data.career_number}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label className="text-black" htmlFor="job_identity_no">
                เลขที่สมาชิกสภาการพยาบาล:
              </label>
              <input
                type="text"
                name="job_identity_no"
                id="job_identity_no"
                onChange={this.onChange}
                disabled={disabled}
                value={this.state.data.job_identity_no}
                className="form-control"
              />
            </div>
          </div>
          <div className="pb-4">
            {btn_print}
            {btn_submit}
          </div>
        </form>
      </FrontendBoxContainer>
    );
  }
}

export default Guest;
