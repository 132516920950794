import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown/Dropdown';
import FrontendBoxContainer from './../../../components/FrontendBoxContainer/FrontendBoxContainer';
import { Services, Alert } from '../../Service/Services';
const $ = window.$;

class Workshop extends Component {
	state = {
        redirect: false,
        errors: [],
        loading: true,
		data: [],
		members: [],
		url: {
			get: '/workshop/show',
			register: '/workshop/register',
			unregister: '/workshop/register/u',
		},
	}

    retrieveData = () => {
        axios.get(this.state.url.get).then( res => {
			const data = res.data;
			const members = data.members;

            this.setState({ data: data.data, members, loading: false });
        })
    }

	componentDidMount = () => {
		this.retrieveData();
	}

	onSubmit = (e) => {
		e.preventDefault();

		let params = this.state.data;
        const url = this.state.url.post;

        this.setState({ loading: true });

        Services.submitRequest({ params, url }).then( res => { 
            this.setState({ loading: false });
        }).catch( res => {
            this.setState({ errors: res, loading: false });
        });
	}

	registerWorkshop = (e, member_id, workshop_id) => {
		e.preventDefault();

		const params = {
			url: this.state.url.register,
			params: {
				member_id,
				workshop_id,
			},
		}

		this.setState({ loading: true });

		Services.submitRequest(params).then( res => {
			this.retrieveData();
		});
	}

	unregisterWorkshop = (e, member_id, workshop_id) => {
		e.preventDefault();

		Alert.confirm("คุณแน่ใจไหมที่จะยกเลิกการลงทะเบียน").then( res => {
			if(res === true){
				const params = {
					url: this.state.url.unregister,
					params: {
						member_id,
						workshop_id,
					},
				}
		
				this.setState({ loading: true });
		
				Services.submitRequest(params).then( res => {
					this.retrieveData();
				});
			}
		})
	}

    render() {
		const data = this.state.data.map((item, item_key) => {
			const detail = item.detail.map((detail, detail_key) => {
				const workshop_id = detail.id;
				const room_name = detail.room_name;
				const workshop_name = detail.workshop_name;
				const capacity = detail.capacity;
				const time = detail.time;
				const sections = detail.sections.join(', ');
				const workshop_members_count = detail.workshop_members_count;
				const full = (workshop_members_count >= capacity);
				const status = (full ? <span className='badge badge-danger'>เต็ม</span> : <span className='badge badge-success'>ว่าง</span>)
				const button_id = ('collapse_' + detail_key + '_' + item_key);
				const register_btn = (<button className="btn btn-primary" type="button" data-toggle="collapse" data-target={'#' + button_id} aria-expanded="false" aria-controls={button_id}>ลงทะเบียน <i className="fa fa-angle-double-down" aria-hidden="true"></i></button>);

				const workshop_members = detail.workshop_members.map( (workshop_member, workshop_member_index) => {
					return (<span key={workshop_member_index} className="badge badge-secondary mr-2">{workshop_member.name} <a className='text-danger cursor-pointer' onClick={(e) => this.unregisterWorkshop(e, workshop_member.member_id, workshop_member.workshop_id)}><i className="fa fa-times" aria-hidden="true"></i></a></span>);
				});

				const register_people = this.state.members.map( (member, member_index) => {
					let register_person_btn = (<button className='btn btn-primary btn-sm mb-2' onClick={(e) => this.registerWorkshop(e, member_id, workshop_id)}>ลงทะเบียน</button>);
					const member_id = member.id;
					
					const workshop_member = detail.workshop_members.find( f => f.member_id === member_id );

					if(workshop_member !== undefined){
						register_person_btn = (<button className='btn btn-danger btn-sm mb-2' onClick={(e) => this.unregisterWorkshop(e, member_id, workshop_id)}>ยกเลิกการลงทะเบียน</button>);
					}

					const columns = (<>
						<td className='text-center'>{member_index + 1}</td>
						<td className='text-left'>{member.name}</td>
						<td className='text-center'>{register_person_btn}</td>
					</>);

					return (<tr key={member_index}>{columns}</tr>)
				});

				return (
					<div className='row mt-2 border-bottom pb-3' key={detail_key}>
						<div className='col-2 text-center'>
							{time}
						</div>
						<div className='col-10 text-left'>
							{room_name} - {workshop_name} <br/>
							<small className='text-muted'>{sections}</small>
							<div>
								จำนวนที่รองรับ {workshop_members_count} / {capacity} {status}
							</div>
							<div>
								{workshop_members}
							</div>
						</div>
						<div className='col-12 text-center mt-2'>
							<p>
								{register_btn}
							</p>
							<div className="collapse" id={button_id}>
								<div className="card card-body">
									<table className='table table-striped table-borderless'>
										<thead>
											<tr>
												<th className='text-center' scope="col">#</th>
												<th className='text-center' scope="col">ชื่อ - นามสกุล</th>
												<th className='text-center' scope="col">การลงทะเบียน</th>
											</tr>
										</thead>
										<tbody>
											{register_people}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				);
			})
			
			return (
				<div className="row form-group" key={item_key}>
					<div className="col-md-12 mb-3 mb-md-0">
						<h4 className="h4 text-black">
							{item.transaction_date_show}
						</h4>
						{detail}
					</div>
				</div>
			);
		});

        return (
            <FrontendBoxContainer 
            loading={this.state.loading}
            title='ลงทะเบียน Workshop'
            errors={this.state.errors}
            >
                <form action="#" className="p-5 bg-white text-left main-form" onSubmit={this.onSubmit}>
                    <div className="">
                        <div className="">
							{data}
                        </div>
                    </div>
                </form>   
            </FrontendBoxContainer>
        )
    }
}

export default Workshop