import React, { Component } from "react";
import axios from "../../../axios";
import { BrowserRouter, Route } from "react-router-dom";
import { Services, Auth, Alert } from "../../Service/Services";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
const $ = window.$;

class Certificate extends Component {
  state = {
    datasource: [],
    loading: false,
    url: {
      search: "/certificate/show",
      print: "/certificate/print",
    },
    search: "",
  };

  onChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  componentDidMount = () => {
    // this.retrieveData();
  };

  retrieveData = () => {
    const params = {
      search: this.state.search,
    };

    axios.post(this.state.url.search, params).then((res) => {
      const datasource = res.data;

      this.setState({ datasource, loading: false });
    });
  };

  printCertificate = (type, id) => {
    const base_url = axios.defaults.baseURL;
    const url = `${base_url}${this.state.url.print}/${type}/${id}`;
    window.open(url, "_blank");
  };

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    this.retrieveData();
  };

  render() {
    const datasource = this.state.datasource.map((data, index) => {
      const type = data.type;
      let type_name;
      if (type === "A" || type === "AA" || type === "BH") {
        type_name = <span className="badge badge-info">ผลงานวิชาการ</span>;
      } else if (type === "M") {
        type_name = (
          <span className="badge badge-secondary">ผู้เข้าร่วมงาน</span>
        );
      } else if (type === "G") {
        type_name = <span className="badge badge-success">แขกรับเชิญ</span>;
      } else if (type === "K") {
        type_name = <span className="badge badge-warning">วิทยากร</span>;
      }
      const id = data.id;
      const no = data.no;

      return (
        <tr key={index}>
          <td className="text-left">{type_name}</td>
          <td className="text-center">{no}</td>
          <td className="text-left">{data.name}</td>
          <td className="text-left">{data.company_name}</td>
          <td className="text-center">
            <input
              type="button"
              className="btn-sm btn btn-secondary"
              value="พิมพ์ประกาศนียบัตร"
              onClick={() => this.printCertificate(type, id)}
            />
          </td>
        </tr>
      );
    });

    return (
      <BrowserRouter>
        <FrontendBoxContainer
          loading={this.state.loading}
          title="ประกาศนียบัตร"
          subtitle="Certificate"
        >
          <div className="bg-white p-5">
            <div className="card">
              <div className="card-header text-left">ค้นหา</div>
              <div className="card-body">
                <form className="text-left" onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        required
                        placeholder="รหัสผลงานวิชาการ  รหัสลงทะเบียน"
                        id="search"
                        name="search"
                        onChange={this.onChange}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary btn-block">
                    ค้นหา
                  </button>
                </form>
              </div>
            </div>
            <div className="mt-3">
              <div className="card-body text-left pt-0">
                <table className="table w-100">
                  <thead>
                    <tr>
                      <th className="text-center">ประเภท</th>
                      <th className="text-center">รหัสลงทะเบียน</th>
                      <th className="text-center">ชื่อ</th>
                      <th className="text-center">สถาบัน</th>
                      <th className="text-center">#</th>
                    </tr>
                  </thead>
                  <tbody>{datasource}</tbody>
                </table>
              </div>
            </div>
          </div>
        </FrontendBoxContainer>
      </BrowserRouter>
    );
  }
}

export default Certificate;
