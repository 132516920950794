import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import News from "./News";
import Agenda from "./Agenda";
import Galleries from "./Galleries";
import KeynoteSpeaker from "./KeynoteSpeaker";
import AbstractDeadline from "./AbstractDeadline";
import Banner from "./Banner";
import Sponsor from "./Sponsor";
import Notify from "./Notify";
import axios from "../../../axios";
import { Auth } from "../../Service/Services";

class Home extends Component {
  state = {
    data: {
      banners: [],
      news: [],
      galleries: [],
      sponsors: [],
      agendas: [],
      keynote_speakers: [],
      notify: null,
      popups: [],
    },
  };

  componentDidMount = () => {
    const url = "home";

    axios.get(url).then((res) => {
      const data = res.data;

      this.setState({ data });
    });
  };

  render() {
    const {
      banners,
      news,
      galleries,
      sponsors,
      agendas,
      keynote_speakers,
      notify,
    } = this.state.data;
    let abstract_deadline,
      gallery_element,
      sponsor_element,
      news_element,
      notify_element,
      keynote_speakers_element;

    if (Auth.isAuth() === true) {
      abstract_deadline = <AbstractDeadline></AbstractDeadline>;
    }

    if (galleries.length > 0) {
      gallery_element = <Galleries data={galleries}></Galleries>;
    }

    if (sponsors.length > 0) {
      sponsor_element = <Sponsor data={sponsors}></Sponsor>;
    }

    if (news.length > 0) {
      news_element = <News data={news}></News>;
    }

    if (notify !== null) {
      notify_element = <Notify data={notify}></Notify>;
    }

    if (keynote_speakers.length > 0) {
      keynote_speakers_element = (
        <KeynoteSpeaker data={keynote_speakers}></KeynoteSpeaker>
      );
    }

    return (
      <div>
        <Banner data={banners}></Banner>
        {abstract_deadline}
        {news_element}
        {/* <Agenda data={agendas}></Agenda> */}
        {keynote_speakers_element}
        {gallery_element}
        {sponsor_element}
        {notify_element}
        <a href="/register" className="bg-primary py-5 d-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md10">
                <h2 className="text-white">
                  <i className="fa fa-user"></i> สมัครสมาชิกเข้าใช้งานระบบ
                </h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default Home;
