import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown/Dropdown';
import FrontendBoxContainer from './../../../components/FrontendBoxContainer/FrontendBoxContainer';
import { Services, Alert } from '../../Service/Services';
const $ = window.$;

class Agenda extends Component {
	state = {
        redirect: false,
        errors: [],
        loading: true,
		data: [],
		downloads: [],
	}

    retrieveData = () => {
        axios.get('/agenda/show').then( res => {
			const data = res.data.entities;
			const downloads = res.data.downloads;

            this.setState({ data, loading: false, downloads });
        })
    }

	componentDidMount = () => {
		this.retrieveData();
	}

    render() {

		const data = this.state.data.map((item, key) => {
			var section_date = '';
			const room_name = item.room_name;
			const room_theme = item.room_theme;
			const room_amount = item.room_amount;

			const sections = item.sections.map((sections, sections_key) => {
				const section_detail = sections.map((section, section_key) => {
					const section_time = section.section_time;
					const section_detail = (<td><strong>{section.section_name}</strong> {section.keynote_name}</td>);
					section_date = section.section_date;
	
					return (
						<>
							<div className='col-2 text-center'>{section_time}</div>
							<div className='col-10'>{section_detail}</div>
						</>
					);
				});

				return (
					<>
						<div className='row'>
							<div className='col-12'><strong>{section_date}</strong></div>
							{section_detail}
						</div>
						<hr />
					</>
				);
			});

			return (
				<div className="row form-group" key={key}>
					<div className="col-md-12 mb-3 mb-md-0">
						<h4 className="h4 text-black">
							{room_name}
						</h4>
						<small className='text-muted float-left'>{room_theme}</small>
						<small className='text-muted float-right'>จำนวนที่รองรับ {room_amount}</small>
						<div className='clearfix'></div>
						{sections}
					</div>
				</div>
			);
		});

		const link_download = this.state.downloads.map( item => {
			return Services.htmlParse(item);
		});

        return (
            <FrontendBoxContainer 
            loading={this.state.loading}
            title='กำหนดการ'
            errors={this.state.errors}
            >
				<div className='bg-white text-left p-5'>
					<div className="dropdown mb-3 float-right">
						<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							ดาวน์โหลด
						</button>
						<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
						{ link_download }
						</div>
					</div>
					<div className="clearfix"></div>
					{data}
				</div>
            </FrontendBoxContainer>
        )
    }
}

export default Agenda