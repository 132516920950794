import axios from "../axios";

const getMemberByToken = ({ token, type }) => {
  const promise = new Promise((resolve, reject) => {
    const url = `/memberwithtoken/${token}/${type}`;
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch((res) => reject(res));
  });

  return promise;
};

const memberCheckIn = ({ id, type }) => {
  const promise = new Promise((resolve, reject) => {
    const url = `/membercheckin/${id}/${type}`;
    axios
      .get(url)
      .then((res) => resolve(res.data))
      .catch((res) => reject(res));
  });

  return promise;
};

export default {
  getMemberByToken,
  memberCheckIn,
};
