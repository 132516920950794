import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import defaultLogo from '../../../assets/images/default_logo.png';

var HtmlToReactParser = require('html-to-react').Parser;

class News extends Component {
  render() {
    const news = this.props.data.map((item, key) => {
      let thumbnail = '';
      let short_desc = '';
      let download = '';

      if(item.short_desc){
        short_desc = item.short_desc;
      }else{
        short_desc = item.desc;
      }

      if(item.attach_file_url){
        download = (<a className='mx-2' target='_blank' href={item.attach_file_url}><i className='fa fa-download'></i></a>)
      }

      let htmlToReactParser = new HtmlToReactParser();
      let short_desc_element = htmlToReactParser.parse(short_desc);

      if(item.attach_file_thumbnail_url){
        thumbnail = item.attach_file_thumbnail_url;
      }else{
        thumbnail = defaultLogo;
      }

      const link = "/news/" + item.id;

      return (
        <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" key={key}>
          <div className="h-entry">
            <div className='text-center'>
              <img src={thumbnail} alt="Image" className="img-fluid" style={{'height': '118px'}} />
            </div>
            <h2 className="font-size-regular"><a href={link}>{item.topic}</a></h2>
            <div className="meta mb-4">Administrator <span className="mx-2">•</span>{item.created_date}<span className="mx-2">•</span> <a href="#">News</a>{download}</div>
            {short_desc_element}
            <p><a href={link} target='_blank'>อ่านต่อ...</a></p>
          </div> 
        </div>
      )
    });

    return (
      <section className="site-section bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="text-black h1 site-section-heading text-center">ข่าวสารและกิจกรรม</h2>
              </div>
            </div>

            <div className="row">
              {news}
              
              <div className="col-12 text-center mt-5">
                <a href="/news" className="btn btn-primary btn-md">ทั้งหมด</a>
              </div>
            </div>
          </div>
        </section>
    )
  }
}

News.defaultProps = {
  data: [],
}

export default News