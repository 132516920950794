import React, { Component } from "react";
import axios from "../../axios";
import { Auth } from "../../pages/Service/Services";

class Footer extends Component {
  state = {
    menues: {
      venue: [],
    },
  };

  componentDidMount = () => {
    const authen = Auth.isAuth();

    axios.get("venue").then((res) => {
      const venue = res.data;
      const menues = {
        ...this.state.menues,
        venue,
      };

      this.setState({
        authen,
        menues,
      });
    });
  };

  render() {
    let member, abstract, register, workshop;

    if (Auth.isAuth() === true) {
      member = (
        <li>
          <a href="/member">ลงทะเบียนเข้าร่วมงาน</a>
        </li>
      );
      abstract = (
        <li>
          <a href="/abstract">ส่งผลงาน</a>
        </li>
      );
      workshop = (
        <li>
          <a href="/workshop">Workshop</a>
        </li>
      );
    } else {
      register = (
        <li>
          <a href="/register">สมัครสมาชิก</a>
        </li>
      );
    }

    let venues = this.state.menues.venue.map((item, key) => {
      return (
        <li key={key}>
          {" "}
          - <a href={"/venue/" + item.id}>{item.name}</a>
        </li>
      );
    });

    venues.push(
      <li key={venues.length}>
        {" "}
        - <a href="/hotel">แนะนำที่พัก</a>
      </li>
    );

    const url = axios.defaults.params.baseStorageURL + "/download/";

    return (
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-5">
                  <h2 className="footer-heading mb-4">เกี่ยวกับเรา</h2>
                  <p>สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน)</p>
                  <ul>
                    <li>
                      <a href={url + "file1.pdf"} target="_blank" className="">
                        การปฏิเสธความรับผิดชอบ
                      </a>
                    </li>
                    <li>
                      <a href={url + "file2.pdf"} target="_blank" className="">
                        นโยบายการรักษาความมั่นคงปลอดภัยเว็บไซต์
                      </a>
                    </li>
                    <li>
                      <a href={url + "file3.pdf"} target="_blank" className="">
                        นโยบายเว็บไซต์
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.ha.or.th/TH/Contents/%E0%B8%99%E0%B9%82%E0%B8%A2%E0%B8%9A%E0%B8%B2%E0%B8%A2%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%84%E0%B8%B8%E0%B9%89%E0%B8%A1%E0%B8%84%E0%B8%A3%E0%B8%AD%E0%B8%87%E0%B8%82%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%B9%E0%B8%A5%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%9A%E0%B8%B8%E0%B8%84%E0%B8%84%E0%B8%A5"
                        target="_blank"
                        className=""
                      >
                        นโยบายการคุ้มครองข้อมูลส่วนบุคคล
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <h2 className="footer-heading mb-4">เมนู</h2>
                  <ul className="list-unstyled">
                    <li>
                      <a href="/">หน้าหลัก</a>
                    </li>
                    {abstract}
                    <li>
                      <a href="#">แนะนำสถานที่</a>
                    </li>
                    {venues}
                    <li>
                      <a href="/publishdocument">เอกสารเผยแพร่</a>
                    </li>
                    <li>
                      <a href="/agenda">กำหนดการ</a>
                    </li>
                    {workshop}
                    {member}
                    {register}
                    <li>
                      <a href="/faq">FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <h2 className="footer-heading mb-4">ติดตามเรา</h2>
                  <a
                    target="_blank"
                    href="https://web.facebook.com/HATHAILANDfanpage/"
                    className="pl-0 pr-3"
                  >
                    <span className="icon-facebook"></span>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/user/HAIThailand"
                    className="pl-3 pr-3"
                  >
                    <span className="icon-youtube"></span>
                  </a>
                  <a
                    target="_blank"
                    href="http://line.me/ti/p/~@hathailand"
                    className="pl-3 pr-3"
                  >
                    <i className="lab la-line lg"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <h2 className="footer-heading mb-4">ติดต่อเรา</h2>
              <p>
                <span className="icon-home"></span> 88/39 อาคารสุขภาพแห่งชาติ
                กระทรวงสาธารณสุข ซ.6 ถ.ติวานนท์ ต.ตลาดขวัญ อ.เมือง จ.นนทบุรี
                เทศบาลนครนนทบุรี <br />
                <a href="mailto:hawebmaster@ha.or.th">
                  <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                  hawebmaster@ha.or.th
                </a>{" "}
                <br />
                <a href="tel:+66028329400">
                  <span className="icon-phone"></span> (+66) 0 2027 8844
                </a>{" "}
                <br />
                <a href="tel:+66028329540">
                  <span className="icon-fax"></span> (+66) 0 2026 6680
                </a>{" "}
                <br />
              </p>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.7943802925006!2d100.52908531460228!3d13.851377698710591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ca05c422683%3A0xcc1f5e12a3b6f27f!2z4Liq4LiW4Liy4Lia4Lix4LiZ4Lij4Lix4Lia4Lij4Lit4LiH4LiE4Li44LiT4Lig4Liy4Lie4Liq4LiW4Liy4LiZ4Lie4Lii4Liy4Lia4Liy4Lil!5e0!3m2!1sen!2sth!4v1573454984956!5m2!1sen!2sth"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
              ></iframe>
            </div>
          </div>

          <div className="row pt-5 mt-5 text-center">
            <div className="col-md-12">
              <div className="border-top pt-5">
                <p>Copyright &copy;2019 All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
