import axios from "../axios";

const getAllUserDeliveryProfile = () => {
  const promise = new Promise((resolve, reject) => {
    const url = "auth/user/address";
    axios
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

const updateUserDeliveryProfile = (data, id = null) => {
  const promise = new Promise((resolve, reject) => {
    let url = `auth/user/address`;
    if (id) url += `/${id}`;

    const params = data;

    axios
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

const deleteUserDeliveryProfile = (id) => {
  const promise = new Promise((resolve, reject) => {
    const url = `auth/user/address/${id}`;
    axios
      .delete(url)
      .then((res) => {
        resolve(res);
      })
      .catch((res) => {
        reject(res);
      });
  });

  return promise;
};

export default {
  getAllUserDeliveryProfile,
  updateUserDeliveryProfile,
  deleteUserDeliveryProfile,
};
