import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import bg_hero from '../../../assets/images/hero_1.jpg'
import Dropdown from '../../../components/Dropdown/Dropdown';
import { Services } from '../../Service/Services'
const $ = window.$;
var HtmlToReactParser = require('html-to-react').Parser;

class Venue extends Component {
	state = {
        data: {
            name: '',
            desc: '',
        },
	}

	componentDidMount = () => {
		const id = this.props.match.params.id;
        const url = `venue/${id}`;

        axios.get(url).then( res => {
            const data = res.data;

            console.log(data);

            this.setState({data});
        });
	}

    render() {
        let { name, desc } = this.state.data;
        const htmlToReactParser = new HtmlToReactParser();
        const desc_element = htmlToReactParser.parse(desc);

        return (
            <div>
        	     <div className="site-blocks-cover inner-page-cover overlay" style={{'backgroundImage': `url(${bg_hero})`}} data-aos="fade" data-stellar-background-ratio="0.5">
        	           <div className="container">
        	                 <div className="row align-items-center justify-content-center text-center">
        	                       <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
        	                             <div className="row justify-content-center mb-4">
        	          <div className="col-md-8 text-center">
        	            <h1>{name}</h1>
        	          </div>
        	        </div>

        	      </div>
        	    </div>
        	  </div>
        	</div>  

        	<section className="site-section bg-light">
        	  <div className="container">
        	    <div className="row">
                    {desc_element}
        	    </div>
        	  </div>
        	</section>
        </div>
        )
    }
}

export default Venue