import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FrontendBoxContainer from "../../components/FrontendBoxContainer/FrontendBoxContainer";
import { Alert, Services } from "../Service/Services";
import moment from "moment";
import { CheckInAPI, SiteAPI } from "../../api";

const NotReadyContainer = ({ availableCheckinDate }) => {
  return (
    <div className="alert alert-warning">
      <h3 style={{ lineHeight: "2" }}>
        <i className="fa fa-warning"></i>&nbsp;ขณะนี้
        ยังไม่ถึงกำหนดเวลาการลงทะเบียนเข้าร่วมงาน ท่านสามารถลงทะเบียนเข้างาน
        ได้ในวันที่ {availableCheckinDate} เวลา 06.00 น - 18:00 น.
        และต้องลงทะเบียนเข้าร่วมงานทุกวัน
      </h3>
    </div>
  );
};

export default ({}) => {
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const [availableCheckinDate, setAvailableCheckinDate] = useState("");
  const [data, setData] = useState({
    id: null,
    name: "",
    email: "",
    identity_number: "",
    career_name: "",
    career_number: "",
    nurse_number: "",
  });
  const { token, type } = useParams();
  const now = moment().locale("th").format("LLL");
  const [checkinHistories, setCheckinHistories] = useState([]);

  useEffect(() => {
    SiteAPI.getSiteInfo().then((res) => {
      setAvailableCheckinDate(res.available_checkin_date);

      CheckInAPI.getMemberByToken({ token, type })
        .then((responseData) => {
          setData(responseData);
          setCheckinHistories(responseData.histories);
          setLoading(false);
          setReady(true);
        })
        .catch((err) => {
          switch (err.status) {
            case 500:
              setLoading(false);
              break;
            case 404:
              // redirect 404
              break;
            default:
            // do something
          }
        });
    });
  }, []);

  return (
    <FrontendBoxContainer
      loading={loading}
      title="ลงทะเบียนเข้าร่วมงาน"
      subtitle={now}
    >
      {loading === true && <div className="p-2 h2">Loading...</div>}
      {loading === false && ready === false && (
        <NotReadyContainer availableCheckinDate={availableCheckinDate} />
      )}
      {ready === true && (
        <div className="row">
          <div className="col-12 col-md-6 text-left">
            <div className="p-4 mb-3 bg-white">
              <h3 className="h5 text-black mb-3">ข้อมูลผู้เข้าร่วมงาน</h3>
              <p className="mb-0 font-weight-bold">
                <i className="fa fa-user"></i> ชื่อ - นามสกุล
              </p>
              <p className="mb-4">{data.name}</p>
              <p className="mb-0 font-weight-bold">รหัสบัตรประชาชน</p>
              <p className="mb-4">{data.identity_number}</p>
              <p className="mb-0 font-weight-bold">วิชาชีพ</p>
              <p className="mb-4">{data.career_name}</p>
              <p className="mb-0 font-weight-bold">เลขที่ประกอบวิชาชีพ</p>
              <p className="mb-4">{data.career_number}</p>
              <p className="mb-0 font-weight-bold">เลขสภาการพยาบาล</p>
              <p className="mb-4">{data.nurse_number}</p>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <div className="p-4 mb-3 bg-white">
              <h3 className="h5 text-black mb-3">ประวัติการลงทะเบียน</h3>
              {checkinHistories.length === 0 && (
                <div className="alert alert-warning">
                  <i className="fa fa-exclamation"></i> ยังไม่มีการลงทะเบียน
                </div>
              )}
              {checkinHistories.length > 0 && (
                <ul className="list-unstyled text-left">
                  {checkinHistories.map((checkIn) => {
                    return (
                      <li key={checkIn.id}>
                        <i className="fa fa-clock-o"></i>{" "}
                        {moment(checkIn.checkin_date).format(
                          "MMMM Do YYYY, h:mm:ss"
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
              <a
                onClick={() => {
                  Alert.confirm(
                    "คุณแน่ใจไหมที่จะลงทะเบียนเข้าร่วมงานของวันนี้"
                  ).then((res) => {
                    if (res === true) {
                      // Checkin
                      setLoading(true);
                      CheckInAPI.memberCheckIn({ id: data.id, type }).then(
                        (responseData) => {
                          Alert.done("ลงทะเบียนเข้าร่วมงานสำเร็จ").then(() => {
                            setCheckinHistories(responseData);
                            setLoading(false);
                          });
                        }
                      );
                    }
                  });
                }}
                className="btn btn-primary btn-md text-white btn-block"
                style={{ fontSize: "1.2em" }}
              >
                <i className="fa fa-sign-in"></i> ลงทะเบียนเข้าร่วมงาน
              </a>
              <div className="text-left mt-3 text-danger">
                <i className="fa fa-info-circle"></i> ถ้าข้อมูลไม่ถูกต้อง
                กรุณาติดต่อจุดลงทะเบียนเพื่อแก้ไขข้อมูล
              </div>
            </div>
          </div>
        </div>
      )}
    </FrontendBoxContainer>
  );
};
