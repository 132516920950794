import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
// import bg_hero from '../../../assets/images/hero_1.jpg'
import defaultLogo from "../../../assets/images/default_logo.png";
import { Services } from "../../Service/Services";

var HtmlToReactParser = require("html-to-react").Parser;

class NewsDetail extends Component {
  state = {
    redirect: false,
    loading: true,
    errors: [],
    data: {
      news_type: "",
      link: "",
      topic: "",
      short_desc: "",
      desc: "",
      attach_file_url: "",
      attach_file_thumbnail_url: "",
      created_date: "",
      id: "",
      youtube_id: "",
    },
    url: "news",
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  componentDidMount = () => {
    const id = this.props.match.params.id;

    axios
      .get(this.state.url + "/" + id)
      .then((res) => {
        const data = res.data;

        this.setState({
          data,
          loading: false,
        });
      })
      .catch((res) => {
        if (res.status === 404) {
          this.setState({
            redirect: true,
          });
        }
      });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = "/404";

      return <Redirect to={url} />;
    }

    return;
  };

  render() {
    const redirect = this.getRedirect();
    const {
      news_type,
      link,
      topic,
      short_desc,
      desc,
      attach_file_url,
      attach_file_thumbnail_url,
      created_date,
      id,
      youtube_id,
    } = this.state.data;
    let thumbnail, topic_detail, attach_file_element, youtube;

    let htmlToReactParser = new HtmlToReactParser();
    let desc_element = htmlToReactParser.parse(desc);

    if (attach_file_thumbnail_url) {
      thumbnail = attach_file_thumbnail_url;
    } else {
      thumbnail = defaultLogo;
    }

    if (attach_file_url) {
      attach_file_element = Services.htmlParse(attach_file_url);
    }

    if (youtube_id !== "") {
      youtube = (
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            src={"https://www.youtube.com/embed/" + youtube_id}
            allowFullScreen
          ></iframe>
        </div>
      );
    }

    topic_detail = (
      <p className="lead mb-5">
        Administrator <span className="mx-2">•</span>
        {created_date}
      </p>
    );

    return (
      <div>
        {redirect}
        <div
          className="site-blocks-cover inner-page-cover overlay"
          style={{ backgroundImage: `url(${thumbnail})` }}
          data-aos="fade"
          data-stellar-background-ratio="0.5"
        >
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>{topic}</h1>
                    {topic_detail}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="site-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {youtube} <br />
                {desc_element} <br />
                {attach_file_element ? (
                  <>
                    <strong>เอกสารแนบ:</strong> {attach_file_element}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="text-center">
              <a href="/news" className="btn btn-primary btn-md">
                ย้อนกลับ
              </a>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NewsDetail;
