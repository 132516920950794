import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import Dropdown from "../../../components/Dropdown/Dropdown";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
import { Services, Alert } from "../../Service/Services";
import cookie from "js-cookie";
import AgreementPopup from "./AgreementPopup";
const $ = window.$;

class Register extends Component {
  state = {
    redirect: false,
    reference_no: "",
    otp_code: "",
    errors: [],
    loading: false,
    data: {
      company_id: "",
      telephone: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      otp: "",
      company_address: "",
      company_sub_district: "",
      company_district: "",
      company_province_id: "",
      company_zip_code: "",
      company_tax_identity_no: "",
      name: "",
    },
    datasource: {
      province: [],
    },
    isShowAgreement: false,
    acceptAgreement: false,
    acceptNews: false,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  componentDidMount = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "province" }],
    };

    axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          province: res.data.province.map((item) => {
            return { key: item.id, label: item.name };
          }),
        };

        this.setState({
          datasource,
        });
      })
      .catch((res) => {
        console.log("error", res);
      });
  };

  onDDLChange = (obj) => {
    let data = { ...this.state.data, ...obj };
    this.setState({ data });
  };

  register = () => {
    console.log("Register", this.state.acceptAgreement);

    let params = this.state.data;
    params.acceptNews = this.state.acceptNews;

    const api_url = "/auth/otp";
    const api_url_register = "/auth/register";

    this.setState({ loading: true });

    axios
      .post(api_url, params)
      .then((res) => {
        const reference_no = res.data.otp.reference_no;

        this.setState({ loading: false });

        swal({
          title: "OTP",
          text:
            "กรุณากรอกหมายเลข OTP เพื่อยืนยันตัวตน หมายเลขอ้างอิง/Reference No: " +
            reference_no,
          icon: "info",
          button: "OK",
          content: {
            element: "input",
            attributes: {
              placeholder: "OTP Code",
              type: "password",
            },
          },
        }).then((otp_code) => {
          params.otp_code = otp_code;
          params.reference_no = reference_no;

          axios
            .post(api_url_register, params)
            .then((res) => {
              swal({
                title: "Success",
                text: "ลงทะเบียนเรียบร้อย",
                icon: "success",
                button: "OK",
              }).then(() => {
                cookie.set("_token_forum", res.data.token);
                const currentUser = res.data.user;
                localStorage.setItem(
                  "currentUser",
                  JSON.stringify(currentUser)
                );
                window.location.href = "/";
              });
            })
            .catch((res) => {
              const error_message = res.data;
              swal({
                title: "Error",
                text: error_message,
                icon: "error",
                button: "OK",
              });
            });
        });
      })
      .catch((res) => {
        var errors = [];
        for (var i in res.data.errors) {
          res.data.errors[i].map((item) => {
            errors.push(item);
          });
        }

        this.setState({ errors, loading: false });

        Services.goToTop();
      });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.state.acceptAgreement === false) {
      this.setState({ isShowAgreement: true });

      return;
    }

    console.log("Debug register 2");
    this.register();
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = "/";

      return <Redirect to={url} />;
    }

    return;
  };

  render() {
    let register_btn = (
      <button className="btn btn-lg btn-block btn-primary">ลงทะเบียน</button>
    );

    if (this.state.loading === true) {
      register_btn = (
        <button className="btn btn-lg btn-block btn-primary" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>{" "}
          Loading...
        </button>
      );
    }

    const redirect = this.getRedirect();

    const company_id = this.state.data.company_id;
    let company_address = "";

    if (company_id !== "" && isNaN(parseInt(company_id)) === true) {
      company_address = (
        <div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_tax_identity_no"
              >
                หมายเลขประจำตัวผู้เสียภาษี
              </label>
              <input
                required
                type="text"
                id="company_tax_identity_no"
                onChange={this.onChange}
                name="company_tax_identity_no"
                value={this.state.data.company_tax_identity_no}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_address"
              >
                ที่อยู่
              </label>
              <input
                required
                type="text"
                id="company_address"
                onChange={this.onChange}
                name="company_address"
                value={this.state.data.company_address}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_sub_district"
              >
                ตำบล
              </label>
              <input
                required
                type="text"
                id="company_sub_district"
                onChange={this.onChange}
                name="company_sub_district"
                value={this.state.data.company_sub_district}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_district"
              >
                อำเภอ
              </label>
              <input
                required
                type="text"
                id="company_district"
                onChange={this.onChange}
                name="company_district"
                value={this.state.data.company_district}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_province_id"
              >
                จังหวัด
              </label>
              <Dropdown
                datasource={this.state.datasource.province}
                name="company_province_id"
                value={this.state.data.company_province_id}
                onChange={this.onDDLChange}
                required={true}
                placeholder="กรุณาเลือก"
              ></Dropdown>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_zip_code"
              >
                รหัสไปรษณีย์
              </label>
              <input
                required
                type="text"
                id="company_zip_code"
                onChange={this.onChange}
                name="company_zip_code"
                value={this.state.data.company_zip_code}
                className="form-control"
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <FrontendBoxContainer
        loading={this.state.loading}
        title="ลงทะเบียน"
        errors={this.state.errors}
      >
        <form
          action="#"
          className="p-5 bg-white text-left"
          onSubmit={this.onSubmit}
        >
          {redirect}
          <h2 className="h4 text-black mb-5 text-center">บัญชีผู้ใช้งาน</h2>

          <div className="card">
            <div className="card-header">ข้อมูลสถาบัน</div>
            <div className="card-body">
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label
                    className="text-black field-required"
                    htmlFor="company_id"
                  >
                    ชื่อสถาบัน/ ช่วยหน่วยงาน / ชื่อโรงพยาบาล
                  </label>
                  <Dropdown
                    name="company_id"
                    value={this.state.data.company_id}
                    onChange={this.onDDLChange}
                    allow_new_item={true}
                    required={true}
                    placeholder="กรุณาเลือก"
                    ajax={true}
                    api_url={"get/company"}
                  ></Dropdown>
                  <small className="text-muted">
                    พิมพ์อย่างน้อย 2 ตัวอักษรเพื่อค้นหา
                    หากไม่พบสามารถพิมพ์ชื่อหน่วยงาน ที่ถูกต้องและกดเลือก
                  </small>
                </div>
              </div>
              {company_address}
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-header">ข้อมูลบัญชี</div>
            <div className="card-body">
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="field-required text-black" htmlFor="name">
                    ชื่อ - นามสกุล
                  </label>
                  <input
                    required
                    type="text"
                    id="name"
                    onChange={this.onChange}
                    name="name"
                    value={this.state.data.name}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label
                    className="field-required text-black"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <input
                    required
                    type="text"
                    id="username"
                    onChange={this.onChange}
                    name="username"
                    value={this.state.data.username}
                    className="form-control"
                  />
                  <small className="text-mute">
                    Username จะต้องเป็นภาษาอังกฤษและตัวเลขเท่านั้น ไม่มีช่องว่าง
                  </small>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="field-required text-black" htmlFor="email">
                    อีเมล
                  </label>
                  <input
                    required
                    type="text"
                    id="email"
                    onChange={this.onChange}
                    name="email"
                    value={this.state.data.email}
                    className="form-control"
                  />
                  <span className="text-muted">
                    กรุณาระบุอีเมลที่ถูกต้อง ระบบจะส่ง OTP Code ผ่านไปทางอีเมล
                  </span>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label
                    className="field-required text-black"
                    htmlFor="telephone"
                  >
                    เบอร์โทรศัพท์
                  </label>
                  <input
                    maxLength="10"
                    required
                    type="text"
                    id="telephone"
                    onChange={this.onChange}
                    name="telephone"
                    value={this.state.data.telephone}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6 mb-3 mb-md-0">
                  <label
                    className="field-required text-black"
                    htmlFor="password"
                  >
                    รหัสผ่าน
                  </label>
                  <input
                    required
                    type="password"
                    id="password"
                    onChange={this.onChange}
                    name="password"
                    value={this.state.data.password}
                    className="form-control"
                  />
                  <small className="text-mute">
                    รหัสผ่านเข้าเว็บไซต์จะต้องประกอบด้วยตัวอักษร A-Z, a-z, 0-9
                    อย่างน้อยอย่างละ 1 ตัวอักษร รวม 8 ตัวอักษร เช่น AAaa1234
                  </small>
                </div>
                <div className="col-md-6 mb-3 mb-md-0">
                  <label
                    className="field-required text-black"
                    htmlFor="password_confirmation"
                  >
                    ยืนยันรหัสผ่าน
                  </label>
                  <input
                    required
                    type="password"
                    id="password_confirmation"
                    onChange={this.onChange}
                    name="password_confirmation"
                    value={this.state.data.password_confirmation}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn btn-link text-link"
            onClick={() => {
              this.setState({ isShowAgreement: true });
            }}
          >
            คำประกาศเกี่ยวกับความเป็นส่วนตัว
          </button>
          <AgreementPopup
            isShow={this.state.isShowAgreement}
            onClose={(acceptAgreement, acceptNews) => {
              if (acceptAgreement === null && acceptNews === null) {
                this.setState({
                  isShowAgreement: false,
                });
              } else {
                this.setState({
                  isShowAgreement: false,
                  acceptAgreement,
                  acceptNews,
                });

                console.log("Debug register 1", acceptAgreement);

                if (acceptAgreement === true) this.register();
              }
            }}
          />
          <hr />
          {register_btn}
        </form>
      </FrontendBoxContainer>
    );
  }
}

export default Register;
