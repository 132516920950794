import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Alert, Services } from "../../Service/Services";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
import AbstractCertificateForm from "./AbstractCertificateForm";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useReducer } from "react";
import { useHistory } from "react-router-dom";
import AbstractInputForm from "./AbstractInputForm";

const ErrorContainer = ({ errors }) => {
  if (errors.length === 0) return null;

  return (
    <div className="alert alert-danger text-left">
      <ul className="list-unstyled">
        {errors.map((e, key) => (
          <li key={key}>
            <i className="fa fa-close"></i> {e}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ACTION = {
  RETRIEVE: "retrieve",
  SET_LOADING: "set_loading",
  SET_DATASOURCE: "set_datasource",
  ON_CHANGE: "on_change",
  ON_FILE_CHANGE: "on_file_change",
  SUBMIT: "submit",
  SET_ERRORS: "set_errors",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.RETRIEVE:
      const { datasource, data } = action.payload;
      const abstract_type = datasource.abstract_type.find(
        (f) => f.key == data.abstract_type_id
      );

      if (abstract_type) data.abstract_type_code = abstract_type.code;

      return {
        ...state,
        data: action.payload.data,
        datasource: datasource,
        loading: false,
      };
    case ACTION.SET_DATASOURCE:
      return {
        ...state,
        datasource: action.payload,
        loading: false,
      };
    case ACTION.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION.ON_CHANGE:
      const { name, value } = action.payload;
      const dataState = { ...state.data, [name]: value };
      if (name === "abstract_type_id") {
        dataState.abstract_type_code =
          state.datasource.abstract_type.find((f) => f.key == value)?.code ??
          "";
        dataState.abstract_topic_id = null;
      }

      return { ...state, data: dataState };
    case ACTION.ON_FILE_CHANGE:
      const { name: fileName, files } = action.payload;

      return { ...state, data: { ...state.data, [fileName]: files[0] } };
    case ACTION.SUBMIT:
      return { ...state, loading: true };
    case ACTION.SET_ERRORS:
      return { ...state, loading: false, errors: action.payload.errors };

    default:
      return state;
  }
};

const AbstractForm = (props) => {
  const initialData = {
    data: {
      author: "",
      EC: "",
      abstract_type_id: "",
      abstract_type_code: "",
      abstract_topic_id: "",
      name: "",
      id: null,
      abstract_file: null,
      asset_file_preview: "",
      poster_file: null,
      present_file_preview: "",
      comments: [],
      name_cert: "",
      email_cert: "",
      coordinator_name_cert: "",
      coordinator_telephone_cert: "",
      coordinator_email_cert: "",
      author_cert: "",
      company_cert: "",
      telephone_cert: "",
      upload_poster: false,
      score: "",
      status: "",
      company_name: "",
      publish: false,
      allow_to_edit_abstract: true,
    },
    datasource: {
      abstract_topic: [],
      abstract_type: [],
    },
    loading: true,
    errors: [],
  };
  const { id, action } = useParams();
  const [state, dispatch] = useReducer(reducer, initialData);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      retrieveData();
    } else {
      getDatasource();
    }
  }, []);

  const onChange = (e) => {
    dispatch({ type: ACTION.ON_CHANGE, payload: e.target });
  };

  const onFileChange = (e) => {
    dispatch({ type: ACTION.ON_FILE_CHANGE, payload: e.target });
  };

  const retrieveData = () => {
    axios.get("abstract/" + id).then((res) => {
      const data = {
        author: res.data.author,
        EC: res.data.EC,
        abstract_type_id: res.data.abstract_type_id,
        abstract_topic_id: res.data.abstract_topic_id,
        name: res.data.name,
        id: id,
        asset_file_preview: res.data.asset_file_preview,
        comments: res.data.comments,
        status: res.data.status,
        present_file_preview: res.data.present_file_preview,
        author_cert:
          res.data.author_cert === null
            ? res.data.author
            : res.data.author_cert,
        company_cert:
          res.data.company_cert === null
            ? res.data.company_name
            : res.data.company_cert,
        telephone_cert: res.data.telephone_cert,
        name_cert:
          res.data.name_cert === null ? res.data.name : res.data.name_cert,
        email_cert: res.data.email_cert,
        coordinator_name_cert:
          res.data.coordinator_name_cert === null
            ? res.data.coordinator_info.name
            : res.data.coordinator_name_cert,
        coordinator_telephone_cert:
          res.data.coordinator_telephone_cert === null
            ? res.data.coordinator_info.telephone
            : res.data.coordinator_telephone_cert,
        coordinator_email_cert:
          res.data.coordinator_email_cert === null
            ? res.data.coordinator_info.email
            : res.data.coordinator_email_cert,
        link_video_poster: res.data.link_video_poster ?? "",
        score: res.data.score,
        publish: res.data.publish,
        upload_poster: res.data.upload_poster,
        allow_to_edit_abstract: res.data.allow_to_edit_abstract,
      };

      if (action === "view") {
        data.publish = true;
        data.allow_to_edit_abstract = false;
      }

      getDatasource(data);
    });
  };

  const getDatasource = (data = null, disabled = false) => {
    const api_url = "datasource";
    const params = {
      tables: [
        { name: "abstract_topic", value: data ? data.abstract_topic_id : null },
        { name: "abstract_type", value: data ? data.abstract_type_id : null },
      ],
    };

    axios.post(api_url, params).then((res) => {
      const datasource = {
        abstract_topic: res.data.abstract_topic.map((item) => {
          return {
            key: item.id,
            label: item.name,
            code: item.code,
            abstract_type_id: item.abstract_type_id,
          };
        }),
        abstract_type: res.data.abstract_type.map((item) => {
          return { key: item.id, label: item.name, code: item.code };
        }),
      };

      if (data === null) {
        dispatch({ type: ACTION.SET_DATASOURCE, payload: datasource });
      } else {
        dispatch({ type: ACTION.RETRIEVE, payload: { datasource, data } });
      }
    });
  };

  const saveData = () => {
    dispatch({ type: ACTION.SUBMIT });

    const params = state.data;
    let form_data = new FormData();

    for (var key in params) {
      if (params[key] !== undefined) {
        form_data.append(key, params[key]);
      }
    }

    let api_url = "/abstract";
    const request_options = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    if (id) {
      api_url += `/${id}`;
      form_data.append("_method", "PUT");
    }

    axios
      .post(api_url, form_data, request_options)
      .then((res) => {
        dispatch({ type: ACTION.SET_LOADING, payload: false });

        swal({
          title: "Success",
          text: "ส่งข้อมูลเสร็จสิ้น",
          icon: "success",
          button: "OK",
        }).then((res) => {
          // redirect
          history.push("/abstract");
        });
      })
      .catch((res) => {
        var errors = [];

        console.log("debug", res);

        var data = res.data;
        for (var i in data.errors) {
          data.errors[i].map((item) => {
            errors.push(item);
          });
        }

        Services.goToTop();

        dispatch({ type: ACTION.SET_ERRORS, payload: { errors } });
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    Alert.confirm("คุณแน่ใจไหมที่จะส่งข้อมูล").then((res) => {
      if (res === true) {
        saveData();
      }
    });
  };

  return (
    <FrontendBoxContainer loading={state.loading} title="ผลงานวิชาการ">
      {state.errors.length > 0 && <ErrorContainer errors={state.errors} />}
      <form action="#" className="p-5 bg-white text-left" onSubmit={onSubmit}>
        <h2 className="h4 text-black mb-5 text-center">ผลงานวิชาการ</h2>
        {state.data.upload_poster === true && (
          <AbstractCertificateForm
            data={state.data}
            onChange={onChange}
            onFileChange={onFileChange}
          />
        )}
        <AbstractInputForm
          datasource={state.datasource}
          data={state.data}
          disabled={state.data.allow_to_edit_abstract === false}
          onChange={onChange}
          onFileChange={onFileChange}
          loading={state.loading}
        />
      </form>
    </FrontendBoxContainer>
  );
};

export default AbstractForm;
