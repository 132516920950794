import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
var HtmlToReactParser = require('html-to-react').Parser;

class Galleries extends Component {
  render() {
  	const galleries = this.props.data.map( (item, key) => {
  		return (
  			<div className="col-md-6 col-lg-4" key={key}>
  			  <a href="#" className="media-1">
  			    <img src={item.url} alt="Image" className="img-fluid" />
  			    <div className="media-1-content">
  			      <h2>{item.title}</h2>
  			      {/*<span className="category">Web Application</span>*/}
  			    </div>
  			  </a>
  			</div>
  		);
  	});

    return (
      <section className="site-section">
	          <div className="container">
	            <div className="row mb-5 justify-content-center">
	              <div className="col-md-8 text-center">
	                <h2 className="text-black h1 site-section-heading text-center">ภาพกิจกรรม</h2>
	                {/*<p className="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, itaque neque, delectus odio iure explicabo.</p>*/}
	              </div>
	            </div>
	          </div>
	          <div className="container-fluid">
	            <div className="row">
	              {galleries}
	            </div>
	          </div>
	    </section>
    )
  }
}
Galleries.defaultProps = {
	data: [],
}

export default Galleries