import React, { Component } from "react";
import Select from "react-select";
import "./Dropdown.css";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import axios from "../../axios";
import _ from "lodash";

const $ = window.$;

class Dropdown extends Component {
  constructor(props) {
    super(props);

    // const wait = 1500; // milliseconds
    // const loadOptions = (inputValue) => this.getAsyncOptions(inputValue);
    // this.debouncedLoadOptions = _.debounce(loadOptions, wait);
  }

  state = {
    selectedOption: null,
  };

  componentDidMount = () => {};

  rerender = () => {};

  onChange = (selectedOption) => {
    this.setState({ selectedOption }, () => {
      // callback setstate
      // console.log('onChange', selectedOption);
      if (this.props.onChange) {
        if (selectedOption !== null) {
          this.props.onChange({ [this.props.name]: selectedOption.value });
        } else {
          this.props.onChange({ [this.props.name]: null });
        }
      }
    });
  };

  checkDatasourceMissMatch(ds1, ds2) {
    var misss_match = false;

    if (ds1.length > 0 && ds2.length > 0) {
      ds1.map((item, index) => {
        if (ds2[index] !== undefined && item.key != ds2[index].key) {
          misss_match = true;
          return;
        }
      });
    }

    return misss_match;
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.value != this.props.value &&
      this.state.selectedOption === null
    ) {
      const { value, text, ajax, datasource, allow_new_item } = nextProps;

      if (value !== "" && value !== null) {
        let selectedOption = null;

        if (ajax === true) {
          selectedOption = {
            value: value,
            label: text || value,
          };
        } else {
          const data = datasource.filter((item) => item.key == value);

          if (data.length > 0) {
            selectedOption = {
              value: data[0].key,
              label: data[0].label,
            };
          } else {
            if (allow_new_item === true) {
              selectedOption = {
                value: value,
                label: value,
              };
            }
          }
        }

        this.setState({ selectedOption });
      }
    } else if (nextProps.value === "" && this.state.selectedOption !== null) {
      console.log("clear ddl value");
      this.setState({ selectedOption: null });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if(prevProps.value != this.props.value && this.state.selectedOption === null){
    //   const { value, text, ajax, datasource, allow_new_item } = this.props;
    //   if(value !== '' && value !== null){
    //     let selectedOption = null;
    //     console.log(this.state.selectedOption, prevState.selectedOption);
    //     if(ajax === true){
    //       selectedOption = {
    //         value: value,
    //         label: (text || value),
    //       };
    //     }else{
    //       const data = datasource.filter( item => item.key == value );
    //       if(data.length > 0){
    //         selectedOption = {
    //           value: data[0].key,
    //           label: data[0].label
    //         }
    //       }else{
    //         if(allow_new_item === true){
    //           selectedOption = {
    //             value: value,
    //             label: value
    //           }
    //         }
    //       }
    //     }
    //     this.setState({ selectedOption });
    //   }
    // }else if(this.props.value === '' && this.state.selectedOption !== null){
    //   console.log('componentDidUpdate', prevProps.value, this.props.value, this.state.selectedOption);
    //   // this.setState({ selectedOption: null });
    // }
  };

  getComponent = () => {
    const { ajax, allow_new_item } = this.props;

    if (ajax === true && allow_new_item === true) {
      return this.getAsyncCreateableComponent();
    } else {
      return this.getCreateableComponent();
    }
  };

  getCreateableComponent = () => {
    const { value, disabled, multiple, datasource, allow_new_item } =
      this.props;

    let selectedOption = this.state.selectedOption;
    let options = datasource.map((item) => {
      const returnValue = {
        value: item.key,
        label: item.label,
      };

      if (item.key == value) {
        selectedOption = returnValue;
      }

      return returnValue;
    });

    let selectComponent;
    if (allow_new_item === true) {
      selectComponent = (
        <CreatableSelect
          isClearable
          className="ddl"
          classNamePrefix="ddl"
          value={selectedOption}
          options={options}
          isDisabled={disabled}
          isMulti={multiple}
          onChange={this.onChange}
        />
      );
    } else {
      selectComponent = (
        <Select
          isClearable
          className="ddl"
          classNamePrefix="ddl"
          value={selectedOption}
          options={options}
          isDisabled={disabled}
          isMulti={multiple}
          onChange={this.onChange}
        />
      );
    }

    return selectComponent;
  };

  getAsyncOptions = (inputValue) => {
    const { api_url } = this.props;

    return new Promise((resolve) => {
      const params = {
        term: { term: inputValue },
      };

      const url = axios.defaults.baseURL + "/" + api_url;

      axios.post(url, params).then((res) => {
        const data = res.data.map((item) => {
          return {
            value: item.id,
            label: item.text,
          };
        });

        resolve(data);
      });
    });
  };

  getAsyncCreateableComponent = () => {
    const { disabled } = this.props;
    let selectedOption = this.state.selectedOption;

    const selectComponent = (
      <AsyncCreatableSelect
        cacheOptions
        isDisabled={disabled}
        defaultOptions
        isClearable
        value={selectedOption}
        loadOptions={this.getAsyncOptions}
        onChange={this.onChange}
      />
    );

    return selectComponent;
  };

  render() {
    let { value } = this.props;
    let selectComponent = this.getComponent();

    // console.log('render', this.state.selectedOption);

    if (value === null) {
      value = "";
    }

    return (
      <div>
        {selectComponent}
        {!this.props.disabled && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              height: 0,
              width: "100%",
              position: "absolute",
            }}
            defaultValue={value}
            required={this.props.required}
          />
        )}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  datasource: [],
  name: "",
  id: "",
  text: "",
  disabled: false,
  value: "",
  onChange: [],
  required: false,
  placeholder: "Please select",
  ajax: false,
  api_url: "",
  allow_new_item: false,
  multiple: false,
};

export default Dropdown;
