import React, { useEffect, useRef, useState } from "react";
import agreementData from "../../../data/agreement";
const $ = window.$;

const AgreementPopup = ({ isShow, onClose }) => {
  const modalElement = useRef();
  const [acceptAgreement, setAcceptAgreement] = useState(false);
  const [acceptNews, setAcceptNews] = useState(false);
  // const [isShowModal, setIsShowModal] = useState(false);
  const onSubmit = () => {
    // console.log("AgreementPopup", acceptAgreement, acceptNews);
    onClose(acceptAgreement, acceptNews);
  };

  useEffect(() => {
    $(modalElement.current).on("hidden.bs.modal", () => {
      // setIsShowModal(false);
      onClose(null, null);
    });
  }, []);

  // useEffect(() => {
  //   setIsShowModal(isShow);
  // }, [isShow]);

  useEffect(() => {
    if (isShow === true) {
      $(modalElement.current).modal("show");
    } else {
      $(modalElement.current).modal("hide");
    }
  }, [isShow]);

  return (
    <div
      className={"modal fade"}
      ref={modalElement}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header bg-primary text-white">
            <h5 className="modal-title">Agreement</h5>
            <button
              type="button"
              className="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body text-left">
            <div
              style={{
                overflowY: "scroll",
                height: "500px",
                backgroundColor: "#e6e6e6",
              }}
              className="p-4"
            >
              {agreementData}
            </div>
            <div className="mb-3 mb-md-0 mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="acceptAgreement"
                  onChange={(e) => {
                    setAcceptAgreement(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="acceptAgreement">
                  ข้าพเจ้าได้อ่านและยินยอมให้ผูกพันตามเงื่อนไขภายใต้ข้อตกลงการให้บริการนี้
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="acceptNews"
                  onChange={(e) => {
                    setAcceptNews(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="acceptNews">
                  ยินยอมให้แจ้งข่าวสารผ่านทางช่องทางอีเมล
                </label>
              </div>
            </div>
          </div>
          <div className="modal-footer text-center">
            <button
              type="button"
              className="btn btn-primary btn-block"
              data-dismiss="modal"
              onClick={onSubmit}
              disabled={acceptAgreement === false}
            >
              ตกลง
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-block mt-0"
              data-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementPopup;
