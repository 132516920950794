import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import defaultLogo from '../../../assets/images/default_logo.png';
const $ = window.$;

var HtmlToReactParser = require('html-to-react').Parser;

class Notify extends Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
  }

  state = {
    
  }

  onChange = e => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value
    }

    this.setState({
      data
    });
  }

  componentDidMount = () => { 
    $(this.element.current).modal('show');
  }

  render() {
  	const { topic, desc } = this.props.data;
  	let desc_element;
  	const htmlToReactParser = new HtmlToReactParser()
  	desc_element = htmlToReactParser.parse(desc);

    return (
      <div className="modal" ref={this.element} tabIndex="-1" role="dialog">
		  <div className="modal-dialog modal-xl" role="document">
		    <div className="modal-content">
		      <div className="modal-header bg-primary text-white">
		        <h5 className="modal-title">{topic}</h5>
		        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div className="modal-body text-left">
		        {desc_element}
		      </div>
		      <div className="modal-footer text-center">
		        <button type="button" className="btn btn-secondary btn-block" data-dismiss="modal">ปิด</button>
		      </div>
		    </div>
		  </div>
		</div>
      )
  }
}

Notify.setDefaultProps = {
  data: {
    topic: '',
    desc: '',
  }
}

export default Notify