import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Services } from "../../Service/Services";
const $ = window.$;

class MemberFormOnlineSelectTheme extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();
  }

  state = {
    data: {
      id: "",
      row_index: "",
      section_online_id: "",
      online_register_type: "",
    },
    datasource: {
      section_online: [],
      payment_period: {
        id: null,
        online_free: "",
        online_selected: "",
        early_bird_online_free: "",
        early_bird_online_selected: "",
        early_bird_onground: "",
      },
    },
    errors: [],
    loading: false,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });

    console.log(name, value, data);
  };

  getEmptyData = () => {
    return {
      id: "",
      section_online_id: "",
      row_index: "",
      online_register_type: "",
    };
  };

  getAPIDatasource = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "section_online" }, { name: "payment_period" }],
    };

    const promise = axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          section_online: res.data.section_online.map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
            };
          }),
          payment_period: res.data.payment_period,
        };

        return datasource;
      })
      .catch((res) => {
        console.log("error", res);
      });

    return promise;
  };

  onSubmit = (e) => {
    e.preventDefault();

    let data = { ...this.state.data };
    data.section_online_name =
      data.online_register_type === "s" && data.section_online_id
        ? this.state.datasource.section_online.find(
            (f) => f.id == data.section_online_id
          ).name
        : "";

    console.log(data);

    this.props.onSubmitTheme(data);

    $(this.modal.current).modal("hide");
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.member !== null) {
      //  && nextProps.member.row_index !== nextState.data.row_index
      let member = nextProps.member;

      this.setState({
        data: member,
      });
    }
  };

  componentDidMount = () => {
    $(this.modal.current).on("hidden.bs.modal", (e) => {
      const data = this.getEmptyData();
      this.setState({ data, errors: [] });
    });

    this.getAPIDatasource().then((result) => {
      this.setState({
        datasource: result,
      });
    });
  };

  render() {
    let save_button = (
      <button type="submit" className="btn btn-primary">
        เลือก
      </button>
    );
    const disabled = this.props.editable === false;

    let error_container;

    if (this.state.errors.length > 0) {
      const errors = this.state.errors.map((item, key) => (
        <li key={key}>
          <i className="fa fa-close"> {item}</i>
        </li>
      ));
      error_container = (
        <div className="alert alert-danger">
          <ul className="list-unstyled">{errors}</ul>
        </div>
      );
    }

    let selected_theme;

    if (this.state.data.online_register_type === "s") {
      let selected_theme_datasource = this.state.datasource.section_online.map(
        (item, key) => {
          const checked = this.state.data.section_online_id == item.id;
          const id = "section_online_id_" + item.id;

          return (
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id={id}
                name="section_online_id"
                required
                className="custom-control-input"
                value={item.id}
                onChange={this.onChange}
                checked={checked}
              />
              <label className="custom-control-label" htmlFor={id}>
                {item.name}
              </label>
              <br />
              <small className="text-warning">
                {Services.htmlParse(item.description)}
              </small>
            </div>
          );
        }
      );

      selected_theme = (
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black">กรุณาเลือก Theme:</label>
            {selected_theme_datasource}
          </div>
        </div>
      );
    }

    return (
      <div
        ref={this.modal}
        className="modal fade"
        id="themeModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary  text-white">
              <h5 className="modal-title" id="exampleModalLabel">
                เลือก Theme
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              action="#"
              className="px-5 bg-white text-left"
              onSubmit={this.onSubmit}
            >
              <div className="modal-body">
                {error_container}
                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="online_register_type_f"
                        name="online_register_type"
                        className="custom-control-input"
                        value="f"
                        onChange={this.onChange}
                        checked={this.state.data.online_register_type === "f"}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="online_register_type_f"
                      >
                        Free Theme -{" "}
                        {Services.convertToMoney(
                          this.props.is_early_bird
                            ? this.state.datasource.payment_period
                                .early_bird_online_free
                            : this.state.datasource.payment_period.online_free
                        )}{" "}
                        ฿
                      </label>
                      <br />
                      <small className="text-muted">
                        สามารถเลือกคละ theme เข้าชมได้ ตลอด 3 วัน
                      </small>
                    </div>

                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="online_register_type_s"
                        name="online_register_type"
                        className="custom-control-input"
                        value="s"
                        onChange={this.onChange}
                        checked={this.state.data.online_register_type === "s"}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="online_register_type_s"
                      >
                        Selected Theme -{" "}
                        {Services.convertToMoney(
                          this.props.is_early_bird
                            ? this.state.datasource.payment_period
                                .early_bird_online_selected
                            : this.state.datasource.payment_period
                                .online_selected
                        )}{" "}
                        ฿
                      </label>
                      <br />
                      <small className="text-muted">
                        เลือกเข้าอบรมได้เฉพาะ 1 theme ตลอด 3 วัน
                      </small>
                    </div>
                  </div>
                </div>

                {selected_theme}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  ปิด
                </button>
                {save_button}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

MemberFormOnlineSelectTheme.defaultProps = {
  editable: true,
  onSubmitTheme: null,
  member: null,
  is_early_bird: false,
};

export default MemberFormOnlineSelectTheme;
