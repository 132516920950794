import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import bg_hero from '../../../assets/images/hero_1.jpg'
import defaultLogo from '../../../assets/images/default_logo.png';

var HtmlToReactParser = require('html-to-react').Parser;

class News extends Component {
  state = {
    redirect: false,
    loading: true,
    errors: [],
    data: [],
    url: 'news',
  }

  onChange = e => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value
    }

    this.setState({
      data, loading: false,
    });
  }

  componentDidMount = () => { 
  	axios.get(this.state.url).then( res => {
  		const data = res.data;

  		this.setState({
  			data,
  		});
  	})
  }

  render() {

  	const news = this.state.data.map( (item, key) => {
  		let thumbnail = '';
		let short_desc = '';
		let download = '';

		if(item.short_desc){
			short_desc = item.short_desc;
		}else{
			short_desc = item.desc;
		}

		if(item.attach_file_url){
			download = (<a className='mx-2' target='_blank' href={item.attach_file_url}><i className='fa fa-download'></i></a>)
		}

		let htmlToReactParser = new HtmlToReactParser();
		let short_desc_element = htmlToReactParser.parse(short_desc);

		if(item.attach_file_thumbnail_url){
			thumbnail = item.attach_file_thumbnail_url;
		}else{
			thumbnail = defaultLogo;
		}

  		return (
  			<div className="col-md-4 col-lg-4 mb-4 mb-lg-4" key={key}>
				<div className="h-entry">
					<div className='text-center'>
						<img src={thumbnail} alt="Image" className="img-fluid mx-auto" />
					</div>

					<h2 className="font-size-regular"><a href="#">{item.topic}</a></h2>
		            <div className="meta mb-4">Administrator <span className="mx-2">•</span>{item.created_date}<span className="mx-2">•</span> <a href="#">News</a>{download}</div>
		            {short_desc_element}
		            <p><a href={"/news/" + item.id} target='_blank'>อ่านต่อ...</a></p>
				</div>
			</div>
  		)
  	});

    return (
      	<div>
		    <div className="site-blocks-cover inner-page-cover overlay" style={{'backgroundImage': `url(${bg_hero})`}} data-aos="fade" data-stellar-background-ratio="0.5">
		        <div className="container">
		        	<div className="row align-items-center justify-content-center text-center">
		            	<div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
		                	<div className="row justify-content-center mb-4">
		          				<div className="col-md-8 text-center">
		            				<h1>ข่าวสาร</h1>
		            				<p className="lead mb-5">News</p>
		          				</div>
		        			</div>
		      			</div>
		    		</div>
		  		</div>
			</div>  

			<section className="site-section">
		  		<div className="container">
		    		<div className="row">
		      			{news}
		    		</div>
		  		</div>
			</section>
		</div>
      )
  }
}

export default News