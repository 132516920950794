import { BehaviorSubject } from "rxjs";
// import config from 'config';
// import { handleResponse } from '../_helpers/index';
import axios from "../../../axios";
import swal from "sweetalert";
import { history } from "../_helpers";
import cookie from "js-cookie";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  doLogout,
};

function login(username, password, otp_code = "", reference_no = "") {
  const params = {
    username,
    password,
    otp_code,
    reference_no,
  };

  const api_url = "/auth/login";

  var promise = new Promise(function (resolve, reject) {
    axios
      .post(api_url, params)
      .then((res) => {
        if (res.data.otp) {
          resolve({ otp: res.data.otp });
        } else {
          cookie.set("_token_forum", res.data.token);
          const currentUser = res.data.user;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
          currentUserSubject.next(currentUser);
          resolve();
        }
      })
      .catch((res) => {
        reject(res);
      });
  });

  // var promise = new Promise(function(resolve, reject){
  //     if(email === 'admin' && password === 'P@ssw0rd'){
  //         const currentUser = {
  //             active: true,
  //             email: "admin@sirichat.com",
  //             id: 1,
  //             is_super_admin: 1,
  //             name: "Super Admin",
  //             password_attempt: 0,
  //             status: "A",
  //             token: null,
  //             username: "admin",
  //         }

  //         localStorage.setItem('currentUser', JSON.stringify(currentUser));
  //         currentUserSubject.next(currentUser);

  //         resolve(currentUser);
  //     }else{
  //         const error = {
  //             response: {
  //                 data: {
  //                     errors: ["password attempt invalid"]
  //                 }
  //             }
  //         }

  //         reject(error);
  //     }
  // });

  return promise;
  // return axios.post('/auth/login', { email, password, remember }).then(res => {
  //     localStorage.setItem('currentUser', JSON.stringify(res.data));
  //     currentUserSubject.next(res.data);
  // });
}

function logout() {
  swal({
    title: "Logout",
    text: "คุณต้องการออกจากระบบหรือไม่",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((logout) => {
    if (logout) {
      // remove user from local storage to log user out
      this.doLogout();
    }
  });
}

function doLogout() {
  localStorage.removeItem("currentUser");
  cookie.remove("_token_forum");
  currentUserSubject.next(null);
  history.push("/");
}
