import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import FileInput from '../../../components/FileInput/FileInput';
import { Services, Alert } from '../../Service/Services';
import FrontendBoxContainer from './../../../components/FrontendBoxContainer/FrontendBoxContainer';

var HtmlToReactParser = require('html-to-react').Parser;


class ChangePassword extends Component {
	state = {
        loading: false,
        errors: [],
        data: {
            old_password: '',
            password: '',
            confirm_password: '',
        }
    }

	onChange = e => {
		const { name, value } = e.target;
		const data = {
            ...this.state.data,
			[name]: value
		}

		this.setState({
			data
		});
	}


	componentDidMount = () => {
		
	}

	onSubmit = (e) => {
        e.preventDefault();

        if (this.state.data.password !== this.state.data.confirm_password) {
            this.setState({ errors: ['รหัสผ่านใหม่กับยืนยันรหัสผ่านใหม่ไม่ตรงกัน'] });

            Services.goToTop();
        } else {
            this.setState({ loading: true });

            const params = this.state.data;
            const url = '/auth/newpassword';
    
            Services.submitRequest({ params, url }).then( res => { 
                this.setState({ loading: false });
            }).catch( res => {
                this.setState({ errors: res, loading: false });
            });
        }
	}

    render() {
        return (
            <FrontendBoxContainer 
                loading={this.state.loading}
                title='เปลี่ยนรหัสผ่าน'
                errors={this.state.errors}
            >
                <form action="#" className="p-5 bg-white text-left" onSubmit={this.onSubmit}>
                    <h2 className="h4 text-black mb-5 text-center">เปลี่ยนรหัสผ่าน</h2>
                    <div className="row form-group">
                        <div className="col-12 mb-3">
                            <label className="field-required text-black" htmlFor="old_password">รหัสผ่านเก่า:</label>
                            <input required type='password' name='old_password' id='old_password' onChange={this.onChange} value={this.state.data.old_password} className='form-control' />
                        </div>
                        <div className="col-12 mb-3">
                            <label className="field-required text-black" htmlFor="password">รหัสผ่านใหม่:</label>
                            <input required type='password' name='password' id='password' onChange={this.onChange} value={this.state.data.password} className='form-control' />
                        </div>
                        <div className="col-12">
                            <label className="field-required text-black" htmlFor="confirm_password">ยืนยันรหัสผ่านใหม่:</label>
                            <input required type='password' name='confirm_password' id='confirm_password' onChange={this.onChange} value={this.state.data.confirm_password} className='form-control' />
                        </div>
                    </div>
                    <button className='btn btn-lg btn-block btn-primary'>เปลี่ยนรหัสผ่าน</button>
                </form>
            </FrontendBoxContainer>
        )
    }
}

export default ChangePassword