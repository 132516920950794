import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import bg_hero from "../../../assets/images/hero_1.jpg";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { Services } from "../../Service/Services";
const $ = window.$;

class ForgetPassword extends Component {
  state = {
    data: {
      email: "",
    },
    loading: false,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let params = this.state.data;
    const api_url = "/auth/forgotpassword";
    this.setState({ loading: true });

    axios.post(api_url, params).then((res) => {
      const text =
        "รีเซ็ตรหัสผ่านเรียบร้อย กรุณาเช็คที่อีเมลเพื่อกำหนดรหัสผ่านใหม่";

      if (res.data.status === false) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "error",
          button: "OK",
        }).then(() => {
          this.setState({ loading: false });
        });
      } else {
        swal({
          title: "Success",
          text: text,
          icon: "success",
          button: "OK",
        }).then(() => {
          window.location.href = "/";
        });
      }
    });
  };

  componentDidMount = () => {};

  render() {
    let reset_btn = (
      <button className="btn btn-lg btn-block btn-primary">
        รีเซ็ตรหัสผ่าน
      </button>
    );

    if (this.state.loading === true) {
      reset_btn = (
        <button className="btn btn-lg btn-block btn-primary" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>{" "}
          Loading...
        </button>
      );
    }

    return (
      <div>
        <div
          className="site-blocks-cover inner-page-cover overlay"
          style={{ backgroundImage: `url(${bg_hero})` }}
          data-aos="fade"
          data-stellar-background-ratio="0.5"
        >
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="row justify-content-center mb-4">
                  <div className="col-md-8 text-center">
                    <h1>ลืมรหัสผ่าน</h1>
                    <p className="lead mb-5">
                      กรุณาระบุข้อมูลข้างต้น
                      ระบบจะส่งอีเมลเพื่อให้ท่านทำการกำหนดรหัสผ่านใหม่ไปทางอีเมล
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="site-section bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                <form
                  action="#"
                  className="p-5 bg-white text-left"
                  onSubmit={this.onSubmit}
                >
                  <div className="row form-group">
                    <div className="col-12 mb-3 mb-md-0">
                      <label
                        className="field-required text-black"
                        htmlFor="username"
                      >
                        Email
                      </label>
                      <input
                        required
                        type="email"
                        id="email"
                        onChange={this.onChange}
                        name="email"
                        value={this.state.data.email}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <hr />
                  {reset_btn}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ForgetPassword;
