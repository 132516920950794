import React, { Component } from "react";
import { Services } from "../../Service/Services";

class PublishFile extends Component {
  state = {};

  onClick = (id) => {
    this.props.onClick(id);
  };

  render() {
    const {
      id,
      name,
      title,
      link,
      category_name,
      subcategory_name,
      icon,
      download_amount,
      index,
      is_folder,
    } = this.props;
    const delay = index * 100;

    if (is_folder) {
      return (
        <>
          <tr>
            <td className="text-center">
              <i className="fa fa-folder"></i>&nbsp;
            </td>
            <td className="p-2" colSpan={3}>
              <a
                className="text-link btn-link btn text-left"
                style={{ whiteSpace: "normal" }}
                onClick={(e) => this.onClick(id)}
              >
                {name}
              </a>
            </td>
          </tr>
        </>
      );
    }

    return (
      <tr>
        <td className="text-center">{Services.htmlParse(icon)}</td>
        <td className="p-2">{title}</td>
        <td className="text-right">{download_amount} ครั้ง</td>
        <td className="text-center">
          <a href={Services.htmlParse(link)} className="text-link btn-link">
            <i className="fa fa-download"></i> Download
          </a>
        </td>
      </tr>
      // <div className="col-md-3 col-6 mb-4 pt-4" data-aos="fade-up" data-aos-delay={delay}>
      //     <div className="unit-4 d-flex align-items-start">
      //         <div className="unit-4-icon mr-3" style={{ 'fontSize': '3em' }}>
      //             { Services.htmlParse(icon) }
      //         </div>
      //         <div>
      //             <h3>{title}</h3>
      //             <small className='text-mute'><strong>{category_name}</strong>{subcategory_name ? (' > ' + subcategory_name) : ''}</small>
      //             <p>จำนวนดาวน์โหลด {download_amount}</p>
      //             <p><a href={ Services.htmlParse(link) }><i className='fa fa-download'></i> Download</a></p>
      //         </div>
      //     </div>
      // </div>
    );
  }
}

PublishFile.defaultProps = {
  title: "",
  link: "",
  category_name: "",
  subcategory_name: "",
  icon: "",
  download_amount: 0,
  index: 0,
};

export default PublishFile;
