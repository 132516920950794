import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import abstractButton from "../../../assets/images/abstract-button.png";
const $ = window.$;

var HtmlToReactParser = require("html-to-react").Parser;

class AbstractDeadline extends Component {
  state = {
    countDownDate: null,
    interval: null,
    url: "abstractperiod/i",
  };

  countdown = () => {
    var now = Date.now();
    var distance = this.state.countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // If the count down is finished, write some text
    if (distance < 0) {
      clearInterval(this.state.interval);
    }

    var days_container = document.getElementById("days");
    var hours_container = document.getElementById("hours");
    var minutes_container = document.getElementById("minutes");
    var seconds_container = document.getElementById("seconds");

    if (days_container) {
      days_container.innerText = days;
      hours_container.innerText = hours;
      minutes_container.innerText = minutes;
      seconds_container.innerText = seconds;
    }
  };

  componentDidMount = () => {
    axios.get(this.state.url).then((res) => {
      const abstract_period = res.data;

      if (abstract_period !== null && abstract_period.end_date_text !== null) {
        var interval;
        var countDownDate = new Date(abstract_period.end_date_text);

        // countDownDate.setUTCHours(23, 59, 59, 999);

        // countDownDate.setHours(23, 59, 59, 999);

        if (countDownDate > new Date()) {
          countDownDate = countDownDate.getTime();

          $(() => {
            interval = setInterval(() => {
              this.countdown();
            }, 1000);

            this.setState({ countDownDate, interval });
          });
        }
      }
    });
  };

  render() {
    let abstract_period;

    if (this.state.countDownDate !== null) {
      abstract_period = (
        <section className="site-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="text-black theme-color my-2">
                  ระยะเวลาสิ้นสุดส่งผลงาน เหลืออีก{" "}
                  <span className="time" id="days"></span> วัน{" "}
                  <span className="time" id="hours"></span> ชั่วโมง{" "}
                  <span className="time" id="minutes"></span> นาที{" "}
                  <span className="time" id="seconds"></span> วินาที
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center">
                <a href="/abstract" className="mx-auto">
                  <img src={abstractButton} alt="ส่งผลงาน" />
                </a>
              </div>
            </div>
          </div>
        </section>
      );
    }

    return <div>{abstract_period}</div>;
  }
}

AbstractDeadline.defaultProps = {};

export default AbstractDeadline;
