import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
var HtmlToReactParser = require('html-to-react').Parser;

class Sponsor extends Component {
  render() {
  	const sponsors = this.props.data.map( (item, key) => {
  		return (
  			<div key={key} className="col-md-3 col-lg-3 mb-5 mb-lg-0 aos-init aos-animate" data-aos="fade" data-aos-delay="100">
  			  <div className="person">
  			  	<a href={item.link} target='_blank'>
  			    	<img src={item.url} alt="Image" className="img-fluid" />
  			    </a>
  			  </div>
  			</div>
  		);
  	})
    return (
      <div className="site-section border-bottom">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-7 text-center border-primary">
              <h2 className="font-weight-light text-primary aos-init aos-animate" data-aos="fade">ผู้สนับสนุน</h2>
            </div>
          </div>
          <div className="row">
            {sponsors}
          </div>
        </div>
      </div>
      )
  }
}

export default Sponsor