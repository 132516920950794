import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import bg_hero from '../../../assets/images/hero_1.jpg'
import Dropdown from '../../../components/Dropdown/Dropdown';
import { Services } from '../../Service/Services'
import queryString from 'query-string'

const $ = window.$;

class ChangePassword extends Component {
  state = {
    data: {
      password: '',
      password_confirmation: '',
    },
    errors: [],
    loading: false,
  }

  onChange = e => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value
    }

    this.setState({
      data
    });
  }

  onSubmit = (e) => { 
    e.preventDefault();

    const values = queryString.parse(this.props.location.search);
    let params = this.state.data;
    params.token = values.token;
    

    const api_url = '/auth/changepassword';
    this.setState({ loading: true });

    axios.post(api_url, params).then( res => {
      if(res.data.status === false){
        var errors = [];
        for (var i in res.data.message) {
            res.data.message[i].map(item => {
                errors.push(item);
            });
        }

        this.setState({ errors, loading: false });
      }else{
        const text = 'รหัสผ่านถูกเปลี่ยนเรียบร้อย';

        swal({
            title: "Success",
            text: text,
            icon: "success",
            button: "OK",
        }).then( () => { 
            window.location.href = '/';
        } );
      }
    })
  }

  render() {
      let error;
      let change_password_btn = <button className='btn btn-lg btn-block btn-primary'>เปลี่ยนรหัสผ่าน</button>;

      if(this.state.loading === true){
          change_password_btn = <button className='btn btn-lg btn-block btn-primary' disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</button>
      }

      if(this.state.errors.length > 0){
          let errors = this.state.errors.map( (e, index) => <li key={index}><i className='fa fa-close'></i> {e}</li>)

          error = <div id='error-container' className='alert alert-danger text-left'><ul className='list-unstyled'>{errors}</ul></div>
      }

      return (
        <div>
          <div className="site-blocks-cover inner-page-cover overlay" style={{'backgroundImage': `url(${bg_hero})`}} data-aos="fade" data-stellar-background-ratio="0.5">
            <div className="container">
              <div className="row align-items-center justify-content-center text-center">
                <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
                  <div className="row justify-content-center mb-4">
                    <div className="col-md-8 text-center">
                      <h1>เปลี่ยนรหัสผ่าน</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        <section className="site-section bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                {error}
                <form action="#" className="p-5 bg-white text-left" onSubmit={this.onSubmit}>
                  <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label className="field-required text-black" htmlFor="password">รหัสผ่าน</label>
                      <input required type="password" id="password" onChange={this.onChange} name='password' value={this.state.data.password} className="form-control" />
                    </div>
                    <div className="col-md-6 mb-3 mb-md-0">
                      <label className="field-required text-black" htmlFor="password_confirmation">ยืนยันรหัสผ่าน</label>
                      <input required type="password" id="password_confirmation" onChange={this.onChange} name='password_confirmation' value={this.state.data.password_confirmation} className="form-control" />
                    </div>
                  </div>
                  <hr />
                  {change_password_btn}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default ChangePassword