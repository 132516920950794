import React, { Component } from 'react'
import axios from '../../../axios';
import { BrowserRouter, Route } from 'react-router-dom';
import { Services, Auth, Alert } from '../../Service/Services';
import FrontendBoxContainer from './../../../components/FrontendBoxContainer/FrontendBoxContainer';
import './FAQ.css';
const $ = window.$;

class FAQ extends Component {
  state = {
		datasource: [],
		loading: true,
		url: {
			get: '/faq/show',
        },
	}

	componentDidMount = () => {
		this.retrieveData();
	}

	retrieveData = () => {
		axios.get(this.state.url.get).then( res => {
			const datasource = res.data;

			this.setState({ datasource, loading: false });
		});
	}

    render() {
        const datasource = this.state.datasource.map( (data, index) => {
            const heading_id = 'heading' + index;
            const collpase_id = 'collapse' + index;
            return (
                <div className="panel panel-default" key={index}>
                    <div className="panel-heading p-3 mb-3" role="tab" id={heading_id}>
                        <h3 className="panel-title">
                            <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href={'#' + collpase_id} aria-expanded="true" aria-controls={collpase_id}>
                                {data.question}
                            </a>
                        </h3>
                    </div>
                    <div id={collpase_id} className="panel-collapse collapse" role="tabpanel" aria-labelledby={heading_id}>
                        <div className="panel-body px-3 mb-4">
                            {Services.htmlParse(data.answer)}
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <BrowserRouter>
                <FrontendBoxContainer
                    loading={this.state.loading}
                    title='FAQ'
                >
                    <div className='bg-white p-5 main-form text-left'>
                        <section className="accordion-section clearfix mt-3" aria-label="Question Accordions">
                            <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                {datasource}
                            </div>
                        </section>
                    </div>
                </FrontendBoxContainer>
            </BrowserRouter>  
        )
  }
}

export default FAQ