import React from 'react'
import ReactDOM from 'react-dom'
import ReactAvatar from 'react-avatar-edit'
import userDefault from '../../assets/images/user.png';
import './Avatar.css';
const $ = window.$;

class Avatar extends React.Component {

  constructor(props) {
    super(props)
    this.fileInput = React.createRef();
    this.preview = React.createRef();
    const src = userDefault;
    this.state = {
      preview: null,
      src
    }
    // this.onCrop = this.onCrop.bind(this)
    // this.onClose = this.onClose.bind(this)
  }

  // onClose() {
  //   // this.setState({preview: null});
  //   // this.props.onChange(null);
  //   // console.log('onClose', preview);
  // }

  // onCrop(preview) {
  //   this.setState({preview})
  //   this.props.onChange(preview);
  // }

  componentDidUpdate = (prevProps, prevState) => {
    // if(prevProps.value !== this.props.value){
    //   const value = this.props.value;
    //   $(this.element.current).val(value).trigger('change');
    // }
    if(this.props.value === '' && prevProps.value !== ''){
        $(this.fileInput.current).val('');
        this.setState({preview: null, src: userDefault});
    }else if(this.props.value !== '' && this.props.value !== this.state.src){
        this.setState({src: this.props.value});
    }
  }

  onBeforeFileLoad = (elem) => {    
    // if(this.props.value === '' && this.state.preview !== null){
    //   elem.target.value = "";
    //   this.setState({preview: null});
    // };
  }

  onChange = (e) => {
    if(e.target.files.length > 0){
      let file = e.target.files[0];
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
    
       reader.onloadend = function (e) {
          this.props.onChange({ [this.props.name]: reader.result });
  
          this.setState({
              src: reader.result,
          })
        }.bind(this);
    }
  }

  onClickImage = e => {
    $(this.fileInput.current).trigger('click');
  }

  onCloseClick = e => {
    $(this.fileInput.current).val('');
    this.setState({src: userDefault});
    this.props.onChange({ [this.props.name]: null });
  }

  render () {
    let closeBtn;

    closeBtn = (<svg
      className='upload-close-icon'
      onClick={this.onCloseClick}
      viewBox="0 0 475.2 475.2"
      width="20px" height="20px">
      <g>
        <path
          d="M405.6,69.6C360.7,24.7,301.1,0,237.6,0s-123.1,24.7-168,69.6S0,174.1,0,237.6s24.7,123.1,69.6,168s104.5,69.6,168,69.6    s123.1-24.7,168-69.6s69.6-104.5,69.6-168S450.5,114.5,405.6,69.6z M386.5,386.5c-39.8,39.8-92.7,61.7-148.9,61.7    s-109.1-21.9-148.9-61.7c-82.1-82.1-82.1-215.7,0-297.8C128.5,48.9,181.4,27,237.6,27s109.1,21.9,148.9,61.7    C468.6,170.8,468.6,304.4,386.5,386.5z"
          fill='red' />
        <path
          d="M342.3,132.9c-5.3-5.3-13.8-5.3-19.1,0l-85.6,85.6L152,132.9c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1    l85.6,85.6l-85.6,85.6c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.6-85.6l85.6,85.6c2.6,2.6,6.1,4,9.5,4    c3.5,0,6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.4-85.6l85.6-85.6C347.6,146.7,347.6,138.2,342.3,132.9z"
          fill='red' />
      </g>
    </svg>);

    return (
      <div className='avatar-container'>
        <img className='upload-img' alt='preview' ref={this.preview} src={this.state.src} onClick={this.onClickImage}></img>
        <div className='upload-cation'>คลิ๊กเพื่ออัพโหลด</div>
        {closeBtn}
        <input 
          type="file" 
          multiple={false}
          ref={this.fileInput}
          accept={this.props.mimeTypes}
          onChange={this.onChange}
          className='upload-fileinput'
        />
      </div>
    )
  }
}

Avatar.defaultProps = {
    onChange: null,
    name: '',
    disabled: false,
    value: '',
    mimeTypes: 'image/jpeg,image/png',
  }

export default Avatar