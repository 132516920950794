import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import bg_hero from '../../../assets/images/hero_1.jpg'
import Dropdown from '../../../components/Dropdown/Dropdown';
import { Services } from '../../Service/Services'
const $ = window.$;

class Hotel extends Component {
	state = {
        // url: {
        //     map: 'map',
        //     booking_document: 'booking_document',
        // },
        data: [ ],
	}

	componentDidMount = () => {
		$(function () {
          $('[data-toggle="tooltip"]').tooltip();
        });

        const url = 'hotel';

        axios.get(url).then( res => {
            const data = res.data;

            this.setState({data});
        });
	}

    getHotelDetail = () => {
        const items = this.state.data.map( item => {
            const types = item.types.map( (type, type_key) => {

                return (
                    <div className='row' key={type_key}>
                        <div className='col-md-8 col-12 text-right'><strong>{type.name}:</strong></div>
                        <div className='col-md-4 col-12 text-right'><u>{Services.convertToMoney(type.price)} ฿</u></div>
                    </div>
                )
            });

            let map, booking_document, car, breakfast, bed, remark, telephone, fax;

            if(item.map){
                const url = item.map;
                map = (<a target='_blank' href={url} className="card-link"><i className='fa fa-map'></i> <u>แผนที่</u></a>)
            }

            if(item.booking_document){
                const url = item.booking_document;
                booking_document = (<a target='_blank' href={url} className="card-link"><i className='fa fa-file-pdf-o'></i> <u>ใบจองห้องพัก</u></a>)
            }

            if(item.facilities.car){
                car = (<span data-toggle="tooltip" data-placement="top" title="มีรถรับส่ง" className='badge badge-secondary text-white px-1'><i className='fa fa-car'></i></span>)
            }

            if(item.facilities.breakfast){
                breakfast = (<span data-toggle="tooltip" data-placement="top" title="รวมอาหารเช้า" className='badge badge-secondary text-white px-1'><i className='fa fa-coffee'></i></span>)
            }

            if(item.additional_bed){
                bed = (<span data-toggle="tooltip" data-placement="top" title="เตียงเสริม" className='badge badge-secondary text-white px-1'><i className='fa fa-bed'></i> {Services.convertToMoney(item.additional_bed)}</span>)
            }

            if(item.remark){
                remark = (<small className='text-muted'>{item.remark}</small>)
            }

            if(item.telephone){
                telephone = (<div><i className='fa fa-phone'></i> {item.telephone}</div>);
            }

            if(item.fax){
                fax = (<div><i className='fa fa-fax'></i> {item.fax}</div>);
            }

            return(
                <div className='col-md-4 my-1'>
                    <div className="card">
                        <div className="card-header">
                            <strong>{item.name}</strong>
                        </div>
                        <div className="card-body">
                            <strong>ประเภทห้องพัก (ราคาต่อห้อง ต่อคืน)</strong>
                            <div className="card-text">
                                {types}
                                {remark}
                            </div>
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                {car} {breakfast} {bed}
                            </li>
                            <li className="list-group-item">
                                {telephone} {fax}
                            </li>
                          </ul>
                        <div className="card-body">
                            {map}
                            {booking_document}
                        </div>
                    </div>
                </div>
            )
        });

        return items;
    }

    render() {
        
        const items = this.getHotelDetail();

        return (
            <div>
        	     <div className="site-blocks-cover inner-page-cover overlay" style={{'backgroundImage': `url(${bg_hero})`}} data-aos="fade" data-stellar-background-ratio="0.5">
        	           <div className="container">
        	                 <div className="row align-items-center justify-content-center text-center">
        	                       <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
        	                             <div className="row justify-content-center mb-4">
        	          <div className="col-md-8 text-center">
        	            <h1>รายชื่อที่พัก/โรงแรมสำหรับผู้เข้าร่วมการประชุมวิชาการ</h1>
        	            {/* <p className="lead mb-5 d-none">ในระหว่างวันที่ 10-13 มีนาคม 2563 ณ อาคารอิมแพ็ค ฟอรั่ม เมืองทองธานี</p> */}
        	          </div>
        	        </div>

        	      </div>
        	    </div>
        	  </div>
        	</div>  

        	<section className="site-section bg-light">
        	  <div className="container">
                <div className='mb-3'>
                    <span className='px-2'><i className='fa fa-car px-1'></i> รถรับส่ง</span>
                    <span className='px-2'><i className='fa fa-coffee px-1'></i> อาหารเช้า</span>
                    <span className='px-2'><i className='fa fa-bed px-1'></i> เตียงเสริม (ต่อคืน ต่อห้อง)</span>
                </div>
        	    <div className="row">
                    {items}
        	    </div>
        	  </div>
        	</section>
        </div>
        )
    }
}

export default Hotel


// {
//     name: 'โรงแรมเซ็นทราศูนย์ราชการและคอนเวนชันเซ็นเตอร์ แจ้งวัฒนะ',
//     types: [
//         { name: 'ห้องพักเดี่ยว (1 คน)', price: 2200 },
//         { name: 'ห้องพักเดี่ยว (2 คน)', price: 2400 },
//         { name: 'ห้องพักคู่ (1 คน)', price: 1700 },
//         { name: 'ห้องพักคู่ (2 คน)', price: 1900 },
//         { name: 'ห้องพักสวีท เดี่ยว (1 คน)', price: 2800 },
//         { name: 'ห้องพักสวีท เดี่ยว (2 คน)', price: 3000 },
//     ],
//     telephone: '0 2143 1234 ต่อ 8330, 8332',
//     fax: '0 2143 1239',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document1.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรมลักซอร์',
//     types: [
//         { name: 'ห้องพักเดี่ยว', price: 1300, },
//         { name: 'ห้องพักคู่', price: 1400, },
//         { name: 'ห้องพักเดี่ยว/คู่ (ไม่รวมรถรับ-ส่ง)', price: 1200, },
//     ],
//     telephone: '0 2192 4000, คุณอา ไพ ชินวัฒนา (หลิน) 081-823-0235',
//     fax: '0 2192 4049',
//     remark: '',
//     map: 'map2.pdf',
//     booking_document: 'booking_document2.pdf',
//     facilities: {
//         car: true,
//         breakfast: true,
//     },
//     additional_bed: 600,
// },
// {
//     name: 'โรงแรมทีเค พาเลซ',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1500, },
//         { name: 'ห้องพักคู่ (Twin)', price: 1700, },
//     ],
//     telephone: '0 2574 1588 ต่อ 1113',
//     fax: '0 25742622',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document3.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรมริชมอนด์',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1900, },
//         { name: 'ห้องพักคู่ (Twin)', price: 1900, },
//         { name: 'ห้องพัก 3 ท่าน', price: 2800, },
//     ],
//     telephone: '0 2831 8811',
//     fax: '0 2831 8877, 0 2831 8855',
//     remark: '',
//     map: 'map4.pdf',
//     booking_document: 'booking_document4.pdf',
//     facilities: {
//         car: true,
//         breakfast: true,
//     },
//     additional_bed: null,
// }
// ,
// {
//     name: 'โรงแรมเบสเวสเทริน',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1400 },
//         { name: 'ห้องพักคู่ (Twin)', price: 1600 },
//     ],
//     telephone: '0 2992 6999 ต่อ 7264, 7266',
//     fax: '0 2992 6828',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document5.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// }
// ,
// {
//     name: 'โรงแรมมิราเคิลแกรนด์ คอนเวนชั่น',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1900 },
//         { name: 'ห้องพักคู่ (Twin)', price: 2200 },
//     ],
//     telephone: '02-575-5599 ต่อ แผนกสำรองห้องพัก 1112-1114',
//     fax: '02-575-5655',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document6.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: 1100,
// },
// {
//     name: 'โรงแรมอมารี แอร์พอร์ต',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1700 },
//         { name: 'ห้องพักคู่ (Twin)', price: 1900 },
//         { name: 'ห้องพัก 3 ท่าน', price: 2900 },
//     ],
//     telephone: '0 2566 1020',
//     fax: '0 2928 1500',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document7.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรมบัดดี้ โอเรียนทอล ริเวอร์ไซต์',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1700 },
//         { name: 'ห้องพักคู่ (Twin)', price: 1900 },
//     ],
//     telephone: '0 2584 2222',
//     fax: '0 2584 3888',
//     remark: '',
//     map: 'map8.pdf',
//     booking_document: 'booking_document8.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรมไอบิส อิมแพค เมืองทองธานี',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1600 },
//         { name: 'ห้องพักคู่ (Twin)', price: 1600 },
//     ],
//     telephone: '02 011 7800 ต่อ 04',
//     fax: '02 011 7999',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document9.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรมโนโวเทล อิมแพค เมืองทองธานี',
//     types: [
//         { name: 'ห้องพัก Standard เตียงเดี่ยว', price: 2600 },
//         { name: 'ห้องพัก Standard เตียงคู่', price: 2600 },
//         { name: 'ห้องพัก Premier  เตียงเดี่ยว', price: 4000 },
//         { name: 'ห้องพัก Premier  เตียงคู่', price: 4000 },
//         { name: 'ห้องพัก Suite  เตียงเดี่ยว/คู่', price: 5000 },
//     ],
//     telephone: '0 2833 4888 ต่อ 4710-4715',
//     fax: '0 2833 4889',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document10.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรมไมด้า งามวงศ์วาน',
//     types: [
//         { name: 'ห้องพัก Superior เตียงเดี่ยว', price: 1400 },
//         { name: 'ห้องพัก Superior เตียงคู่', price: 1600 },
//         { name: 'ห้องพัก Deluxe เตียงเดี่ยว', price: 1900 },
//         { name: 'ห้องพัก Deluxe เตียงคู่', price: 1900 },
//     ],
//     telephone: '0 25917227',
//     fax: '0 25917166',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document11.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรมไมด้า ดอนเมือง แอร์พอร์ต กรุงเทพฯ (โรงแรมไมด้า ซิตี้ รีสอร์ท กรุงเทพ)',
//     types: [
//         { name: 'ห้องพัก Superior เตียงเดี่ยว (1 คน)', price: 1500 },
//         { name: 'ห้องพัก Superior เตียงคู่ (2 คน)', price: 1700 },
//         { name: 'ห้องพัก Deluxe เตียงเดี่ยว/คู่ (1 คน)', price: 1900 },
//     ],
//     telephone: '0 2574 1648 ต่อ 2103, 02- 574 1648 ต่อ 1',
//     fax: '02-574 1648 ต่อ 2127',
//     remark: '',
//     map: '',
//     booking_document: 'booking_document12.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: 1000,
// },
// {
//     name: 'โรงแรมนนทบุรี พาเลซ',
//     types: [
//         { name: 'ห้องพักเดี่ยว (Single)', price: 1500 },
//         { name: 'ห้องพักคู่ (Twin)', price: 1500 },
//         { name: 'ห้องพัก 3 ท่าน', price: 1800 },
//     ],
//     telephone: '02-969-0160-9 ( 24 ชั่วโมง )',
//     fax: '',
//     remark: '',
//     map: 'map2.jpg',
//     booking_document: 'booking_document13.pdf',
//     facilities: {
//         car: true,
//         breakfast: true,
//     },
//     additional_bed: null,
// },
// {
//     name: 'ศูนย์สัมมนาและฝึกอบรม',
//     types: [
//         { name: 'ห้องพักคู่ (Twin) อาคารสัมมนา 1', price: 600 },
//         { name: 'ห้องพักคู่ (Twin) อาคารสัมมนา 2', price: 840 },
//     ],
//     telephone: '0-2504-8711-3',
//     fax: '',
//     remark: 'มีรถ Impact บริการ บริเวณ Top Supermarket ห้าง Bee Hive, โทรสำรองห้องพักเท่านั้น',
//     map: '',
//     booking_document: '',
//     facilities: {
//         car: false,
//         breakfast: false,
//     },
//     additional_bed: null,
// },
// {
//     name: 'โรงแรม เดอะ เล็คกาซี',
//     types: [
//         // { name: 'ห้องพักเดี่ยว / ห้องพักคู่', price: 1500 }
//         { name: 'ห้องพัก Superior (เดี่ยว/คู่)', price: 1400 },
//         { name: 'ห้องพัก Deluxe (เดี่ยว/คู่)', price: 1500 }
//     ],
//     telephone: '086-314-1352',
//     fax: '',
//     remark: '',
//     map: 'map15.pdf',
//     booking_document: 'booking_document15.pdf',
//     facilities: {
//         car: false,
//         breakfast: true,
//     },
//     additional_bed: null,
// }