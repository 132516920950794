import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import MemberFormPopup from "./MemberFormPopup";
import { Services, Alert } from "./../../Service/Services";
import { Redirect } from "react-router-dom";

var HtmlToReactParser = require("html-to-react").Parser;
const $ = window.$;

export const getMemberName = (member, datasource) => {
  let prefix_text = "";
  if (member.prefix_id) {
    if (Services.isNumeric(member.prefix_id)) {
      const prefix = datasource.prefix_name.find(
        (f) => f.key === parseInt(member.prefix_id)
      );

      if (prefix) {
        prefix_text = prefix.label;
      } else {
        prefix_text = "";
      }
    } else {
      prefix_text = member.prefix_id;
    }
  }

  return (
    <span>
      {prefix_text}
      {member.first_name} {member.last_name}
    </span>
  );
};

class MemberFormStep1 extends Component {
  constructor(props) {
    super(props);
    this.import_file_element = React.createRef();
  }

  state = {
    // data: {
    // 	members: [],
    // },
    url: {
      download: axios.defaults.baseURL + "/member/download",
      upload: axios.defaults.baseURL + "/member/import",
    },
    selected_member: null,
  };

  deleteMember = (key) => {
    Alert.confirm("คุณแน่ใจไหมที่จะลบข้อมูล").then((res) => {
      if (res === true) {
        let members = [...this.props.data];

        members.splice(key, 1);

        this.props.onChange(members);
      }
    });
  };

  editMember = (key) => {
    let selected_member = [...this.props.data][key];
    selected_member.row_index = key;

    this.setState({ selected_member });
  };

  openFile = (e) => {
    $(this.import_file_element.current)
      .trigger("click")
      .on("change", (e) => {
        this.props.onLoading(true);
        const url = "member/import";
        const params = {
          url,
          params: {
            import: e.target.files[0],
            id: null,
          },
        };

        Services.submitRequestWithFile(params)
          .then((res) => {
            const data = res.data;

            let current_row_index = this.props.data.length;

            const members = data.map((item, key) => {
              return {
                id: "",
                prefix_id: item.prefix_id || item.prefix_text,
                first_name: item.first_name,
                last_name: item.last_name,
                identity_number: item.identity_number,
                email: item.email,
                career_id: item.career_id || item.career_text,
                career_number: item.career_number,
                nurse_number: item.nurse_number,
                row_index: current_row_index++,
              };
            });

            const data_members = [...this.props.data, ...members];

            // this.setState({ data: data_members });
            e.target.value = null;

            this.props.onChange(data_members);
            this.props.onLoading(false);
          })
          .catch((errors) => {
            Alert.error(errors.join("\n"), "มีข้อผิดพลาด");

            $(this.import_file_element.current).val("");

            this.props.onLoading(false);
          });
      });
  };

  onSubmitMember = (member) => {
    let members = [...this.props.data];

    if (member.row_index !== "") {
      members[member.row_index] = member;
    } else {
      members.push(member);
    }

    this.setState({ selected_member: null });

    this.props.onChange(members);
  };

  componentDidMount = () => {};

  componentDidUpdate = (props, nextProps) => {
    // console.log('componentDidUpdate', props, nextProps);
  };

  componentWillReceiveProps = (nextProps) => {};

  shouldComponentUpdate = (props, nextProps) => {
    // console.log('shouldComponentUpdate', props, nextProps, props.data !== nextProps.data);

    return true;
  };

  render() {
    let buttons;
    // && this.props.data.length < 2
    if (this.props.editable === true) {
      buttons = (
        <div>
          <button
            type="button"
            className="btn btn-primary pull-right mx-2"
            data-toggle="modal"
            data-target="#memberModal"
          >
            <i className="fa fa-plus"></i> เพิ่มสมาชิก
          </button>
          <div className="dropdown pull-right">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              นำข้อมูลเข้า
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href={this.state.url.download}>
                ตัวอย่างข้อมูลนำเข้า
              </a>
              <a className="dropdown-item" onClick={this.openFile}>
                นำข้อมูลเข้า
              </a>
              <input
                type="file"
                className="d-none"
                accept=".xlx,.xlsx"
                ref={this.import_file_element}
              ></input>
            </div>
          </div>
        </div>
      );
    }

    const members = this.props.data.map((item, key) => {
      let row_buttons;
      if (this.props.editable === true) {
        row_buttons = (
          <div className="mb-2">
            <a
              className="btn btn-secondary btn-sm"
              onClick={(e) => this.editMember(key)}
              data-toggle="modal"
              data-target="#memberModal"
            >
              <i className="fa fa-pencil"></i>
            </a>
            &nbsp;&nbsp;
            <a
              className="btn btn-danger btn-sm"
              onClick={(e) => this.deleteMember(key)}
            >
              <i className="fa fa-trash"></i>
            </a>
          </div>
        );
      } else {
        row_buttons = (
          <div>
            <a
              className="btn btn-info btn-sm"
              onClick={(e) => this.editMember(key)}
              data-toggle="modal"
              data-target="#memberModal"
            >
              <i className="fa fa-eye"></i>
            </a>
            &nbsp;&nbsp;
          </div>
        );
      }

      return (
        <tr key={key}>
          <td>{getMemberName(item, this.props.datasource)}</td>
          <td className="text-center">{item.identity_number}</td>
          <td className="text-center text-white">{row_buttons}</td>
        </tr>
      );
    });

    return (
      <div className="tab-pane active" role="tabpanel" id="step1">
        <MemberFormPopup
          editable={this.props.editable}
          onSubmitMember={this.onSubmitMember}
          member={this.state.selected_member}
          datasource={this.props.datasource}
        ></MemberFormPopup>
        <h3>ข้อมูลสมาชิก</h3>
        {/* <p><small className='text-mute'>ลงทะเบียนได้สูงสุด 2 คน</small></p> */}
        {buttons}
        <div className="text-left p-5">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">ชื่อ - นามสกุล</th>
                <th className="text-center">เลขที่บัตรประชาชน</th>
                <th className="text-center">#</th>
              </tr>
            </thead>
            <tbody>{members}</tbody>
          </table>
        </div>
        <button
          type="button"
          disabled={this.props.data.length === 0}
          className="btn btn-primary next-step mx-2"
          onClick={() => this.props.changeStep(2)}
        >
          ถัดไป
        </button>
      </div>
    );
  }
}

MemberFormStep1.defaultProps = {
  data: [],
  editable: false,
  onLoading: {},
};

export default MemberFormStep1;
