import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import MemberFormOnlineStep1 from "./MemberFormOnlineStep1";
import MemberFormOnlineStep2 from "./MemberFormOnlineStep2";
import MemberFormOnlineStep3 from "./MemberFormOnlineStep3";
import { Alert, Services } from "../../Service/Services";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";

const $ = window.$;

var HtmlToReactParser = require("html-to-react").Parser;

class MemberFormOnline extends Component {
  state = {
    redirect: false,
    loading: true,
    errors: [],
    step1: [],
    step2: {
      receipt_address_type: "personal",
      receipt_name: "",
      receipt_identity_no: "",
      receipt_address: "",
      receipt_district: "",
      receipt_sub_district: "",
      receipt_zip_code: "",
      receipt_province_id: "",

      document_name: "",
      document_address: "",
      document_district: "",
      document_sub_district: "",
      document_province_id: "",
      document_zip_code: "",
      delivery_document_type: "receipt",
    },
    company_profile: {},
    datasource: {
      career: [],
      province: [],
      prefix_name: [],
    },
    is_printed: false,
    url: {
      invoice: axios.defaults.baseURL + "/invoice/",
    },
    is_early_bird: false,
  };

  onChangeStep1 = (data) => {
    this.setState({
      step1: data,
    });
  };

  onChangeStep2 = (data) => {
    this.setState({
      step2: data,
    });
  };

  componentDidMount = () => {
    $(document).ready(() => {
      //Initialize tooltips
      $(".nav-tabs > li a[title]").tooltip();

      const toggleButton = (index) => {
        this.toggleButton(index);
      };

      $(".wizard .nav-tabs li").on("click.tab", function (e, data) {
        if (data === undefined) {
          const index = $(this).index();

          toggleButton(index);
        }
      });

      //Wizard
      $('a[data-toggle="tab"]').on("show.bs.tab", function (e) {
        var $target = $(e.target);

        if ($target.parent().hasClass("disabled")) {
          return false;
        } else {
          $(".wizard .nav-tabs li.active").removeClass("active");
          $target.parent().addClass("active");
        }
      });

      $(".next-step").click((e) => {
        var $active = $(".wizard .nav-tabs li.active");
        $active.next().removeClass("disabled").addClass("active");
        $active.removeClass("active");

        this.nextTab($active);
      });

      $(".prev-step").click((e) => {
        var $active = $(".wizard .nav-tabs li.active");
        $active.prev().addClass("active");
        $active.removeClass("active");

        this.prevTab($active);
      });
    });

    const id = this.props.match.params.id;
    const is_early_bird =
      window.location.href.toLowerCase().indexOf("earlybird") !== -1;

    if (id !== undefined) {
      axios.get("member/" + id).then((res) => {
        const data = res.data;

        const step1 = data.members.map((item, key) => {
          const prefix =
            item.prefix_id === null ? item.prefix_text : item.prefix_id;
          const career =
            item.career_id === null ? item.career_text : item.career_id;

          return {
            id: item.id,
            email: item.email,
            prefix_id: prefix,
            first_name: item.first_name,
            last_name: item.last_name,
            identity_number: item.identity_number ? item.identity_number : "",
            career_id: career,
            career_number: item.career_number ? item.career_number : "",
            nurse_number: item.nurse_number ? item.nurse_number : "",
            online_register_type: item.online_register_type
              ? item.online_register_type
              : "",
            section_online_id: item.section_online_id
              ? item.section_online_id
              : "",
            section_online_name: item.section_online_name
              ? item.section_online_name
              : "",
            row_index: key,
            avatar: item.avatar,
          };
        });

        const step2 = {
          receipt_address_type: data.receipt_address_type,
          receipt_name: data.receipt_name,
          receipt_identity_no: data.receipt_identity_no,
          receipt_address: data.receipt_address,
          receipt_district: data.receipt_district,
          receipt_sub_district: data.receipt_sub_district,
          receipt_zip_code: data.receipt_zip_code,
          receipt_province_id: data.receipt_province_id,
          document_name: data.document_name,
          document_address: data.document_address,
          document_district: data.document_district,
          document_sub_district: data.document_sub_district,
          document_province_id: data.document_province_id,
          document_zip_code: data.document_zip_code,
          delivery_document_type: data.delivery_document_type,
        };

        this.setState({
          step1,
          step2,
          loading: false,
          is_printed: data.payments.length > 0,
          is_early_bird,
        });
      });
    } else {
      this.setState({ loading: false, is_early_bird });
    }
  };

  nextTab = (elem) => {
    const index = $(elem).index() + 1;
    $(elem)
      .next()
      .find('a[data-toggle="tab"]')
      .trigger("click", { is_button: true });

    this.toggleButton(index);
  };

  prevTab = (elem) => {
    const index = $(elem).index() - 1;
    $(elem)
      .prev()
      .find('a[data-toggle="tab"]')
      .trigger("click", { is_button: true });

    this.toggleButton(index);
  };

  toggleButton = (index) => {
    if (index <= 0) {
      $("#btn_prev").addClass("d-none");
      $("#btn_submit").addClass("d-none");
      $("#btn_invoice").addClass("d-none");

      $("#btn_next").removeClass("d-none");
    } else if (index >= 2) {
      $("#btn_next").addClass("d-none");

      $("#btn_prev").removeClass("d-none");
      $("#btn_submit").removeClass("d-none");
      $("#btn_invoice").removeClass("d-none");
    } else {
      $("#btn_submit").addClass("d-none");
      $("#btn_invoice").addClass("d-none");

      $("#btn_next").removeClass("d-none");
      $("#btn_prev").removeClass("d-none");
    }
  };

  onSubmit = (e, is_print) => {
    e.preventDefault();

    if (is_print === true) {
      Alert.confirm(
        "คุณแน่ใจไหมที่จะพิมพ์ใบแจ้งชำระเงิน เมื่อพิมพ์แล้วจะไม่สามารถแก้ไขข้อมูลผู้เข้าร่วมงานได้"
      ).then((res) => {
        if (res === true) {
          this.save(is_print);
        }
      });
    } else {
      this.save(is_print);
    }
  };

  save = (is_print) => {
    const id = this.props.match.params.id;

    this.setState({ loading: true });

    let params = {
      params: {
        is_online: "1",
        is_early_bird: this.state.is_early_bird ? "1" : "0",
        members: JSON.stringify(this.state.step1),
        document: JSON.stringify(this.state.step2),
        id,
      },
      url: "/member",
    };

    Services.submitRequestWithFile(params)
      .then((res) => {
        if (is_print === true) {
          const url_invoice = this.state.url.invoice + res.data.id;
          axios.get(url_invoice, { responseType: "blob" }).then((res) => {
            var url = window.URL.createObjectURL(res.request.response);

            window.open(url, "_blank");

            this.setState({ loading: false, redirect: true });
          });
        } else {
          this.setState({ loading: false, redirect: true });
        }
      })
      .catch((errors) => {
        // var errors = errors

        this.setState({ errors, loading: false });
      });
  };

  onLoading = (loading) => {
    this.setState({ loading });
  };

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = "/member/thankyou";

      return <Redirect to={url} />;
    }

    return;
  };

  render() {
    let error;

    if (this.state.errors.length > 0) {
      let errors = this.state.errors.map((e) => (
        <li>
          <i className="fa fa-close"></i> {e}
        </li>
      ));

      error = (
        <div className="alert alert-danger text-left">
          <ul className="list-unstyled">{errors}</ul>
        </div>
      );
    }

    const redirect = this.getRedirect();
    const id = this.props.match.params.id;

    let member_form_step1, member_form_step2, member_form_step3;

    member_form_step1 = (
      <MemberFormOnlineStep1
        onLoading={this.onLoading}
        data={this.state.step1}
        editable={this.state.is_printed === false}
        onChange={this.onChangeStep1}
        is_early_bird={this.state.is_early_bird}
      ></MemberFormOnlineStep1>
    );
    member_form_step2 = (
      <MemberFormOnlineStep2
        data={this.state.step2}
        onChange={this.onChangeStep2}
        company_profile={this.state.company_profile}
      ></MemberFormOnlineStep2>
    );
    member_form_step3 = (
      <MemberFormOnlineStep3
        step1={this.state.step1}
        step2={this.state.step2}
      ></MemberFormOnlineStep3>
    );

    return (
      <FrontendBoxContainer
        loading={this.state.loading}
        title="ลงทะเบียนเข้าร่วมงาน"
        subtitle="ลงทะเบียนผู้ที่เข้าร่วมงานประชุม"
      >
        {redirect}
        {error}
        <div className="wizard">
          <div className="wizard-inner">
            <div className="connecting-line"></div>
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className="active">
                <a
                  href="#step1"
                  data-toggle="tab"
                  aria-controls="step1"
                  role="tab"
                  title="Step 1"
                >
                  <span className="round-tab">
                    <i className="fa fa-users"></i>
                  </span>
                </a>
              </li>
              <li role="presentation" className="">
                <a
                  href="#step2"
                  data-toggle="tab"
                  aria-controls="step3"
                  role="tab"
                  title="Step 3"
                >
                  <span className="round-tab">
                    <i className="fa fa-building"></i>
                  </span>
                </a>
              </li>
              <li role="presentation" className="">
                <a
                  href="#complete"
                  data-toggle="tab"
                  aria-controls="complete"
                  role="tab"
                  title="Complete"
                >
                  <span className="round-tab">
                    <i className="fa fa-check"></i>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            {member_form_step1}
            {member_form_step2}
            {member_form_step3}
            <div className="clearfix"></div>
          </div>
          <div className="pb-4">
            <button
              id="btn_prev"
              type="button"
              className="btn btn-primary prev-step mx-2 d-none"
            >
              ย้อนกลับ
            </button>
            <button
              id="btn_next"
              type="button"
              className="btn btn-primary next-step mx-2"
            >
              ถัดไป
            </button>
            <button
              id="btn_submit"
              type="button"
              className="btn btn-primary mx-2 d-none"
              onClick={(e) => this.onSubmit(e, false)}
            >
              ส่งข้อมูล
            </button>
            <button
              id="btn_invoice"
              type="button"
              className="btn btn-primary mx-2 d-none"
              onClick={(e) => this.onSubmit(e, true)}
            >
              พิมพ์ใบแจ้งชำระเงิน
            </button>
          </div>
        </div>
      </FrontendBoxContainer>
    );
  }
}

export default MemberFormOnline;
