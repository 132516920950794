import React, { Component } from 'react'
import axios from '../../axios';
import { services } from '../../pages/Service/Services';
import swal from 'sweetalert';
import Loader from './../Loader/Loader';
import bg_hero from './../../assets/images/hero_1.jpg';
import './FrontendBoxContainer.css';

const $ = window.$;

class FrontendBoxContainer extends Component {
    constructor(props) {
        super(props);

        this.load_content = React.createRef();
    }

    getClassName = () => {
        const enable = this.props.loading;
        const class_names = [];
        const class_loading = 'active-loading';

        if (enable === true) {
            class_names.push(class_loading);
        }

        return class_names.join(' ');
    }

    getErrors = () => {
        return this.props.errors;
    }

    getErrorContainer = () => {
        const errors = this.getErrors().map((error_message, index) => {
            return (<li key={index}><i className='fa fa-times'></i> {error_message}</li>);
        });

        if (errors.length > 0) {
            const error_container = (<div className="alert alert-danger" role="alert">
                <h3 className="alert-heading">มีข้อผิดพลาด !!!</h3>
                <ul className='list-unstyled'>{errors}</ul>
            </div>);

            return error_container;
        }

        return;
    }

    render() {
        const error_container = this.getErrorContainer();

        return (
            <div>
                <div className="site-blocks-cover inner-page-cover overlay" style={{ 'backgroundImage': `url(${bg_hero})` }} data-aos="fade" data-stellar-background-ratio="0.5">
                    <div className="container">
                        <div className="row align-items-center justify-content-center text-center">
                            <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
                                <div className="row justify-content-center mb-4">
                                    <div className="col-md-8 text-center">
                                        <h1>{this.props.title}</h1>
                                        <p className="lead mb-5">{this.props.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="site-section bg-light">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <div className={this.getClassName()} ref={this.load_content}>
                                    {error_container}
                                    <Loader loading={this.props.loading}></Loader>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
FrontendBoxContainer.defaultProps = {
    loading: true,
    errors: [],
    title: '',
    subtitle: '',
}

export default FrontendBoxContainer
