import React, { Component } from "react";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
import { Services } from "../../Service/Services";
import PublishFolder from "./PublishFolder";
import PublishFile from "./PublishFile";

class Publish extends Component {
  state = {
    redirect_url: "",
    errors: [],
    loading: true,
    search: "",
    category_id: "",
    subcategory_id: "",
    category_name: "",
    subcategory_name: "",
    data: [],
    url: {
      search: "/publish/show",
    },
  };

  retrieveData = (category_id = null, subcategory_id = null) => {
    if (category_id === null) {
      category_id = this.props.match.params.category_id || "";
    }
    if (subcategory_id === null) {
      subcategory_id = this.props.match.params.subcategory_id || "";
    }

    const search = this.state.search;

    const params = {
      category_id,
      subcategory_id,
      search,
    };

    axios.post(this.state.url.search, params).then((res) => {
      const data = res.data.datasource;
      const category_name = res.data.category_name;
      const subcategory_name = res.data.subcategory_name;

      this.setState({
        data,
        loading: false,
        category_id,
        subcategory_id,
        category_name,
        subcategory_name,
      });
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount = () => {
    this.retrieveData();

    console.log("componentDidMount");
  };

  onClick = (id) => {
    // this.setState({ redirect_url: '/publishdocument/' + category_id })
    let { category_id, subcategory_id } = { ...this.state };
    let url = `/publishdocument`;

    if (category_id) {
      if (subcategory_id === "" || subcategory_id === null) {
        subcategory_id = id;
        url += `/${category_id}/${subcategory_id}`;
      }
    } else {
      category_id = id;
      url += `/${category_id}`;
    }

    this.props.history.push(url);

    this.retrieveData(category_id, subcategory_id);
  };

  onClickBack = () => {
    let { category_id, subcategory_id } = { ...this.state };
    let url = `/publishdocument`;

    if (category_id) {
      if (subcategory_id) {
        subcategory_id = "";
        url += `/${category_id}`;
      } else {
        category_id = "";
      }
    }

    this.props.history.push(url);

    this.retrieveData(category_id, subcategory_id);
  };

  onSubmit = (e) => {
    e.preventDefault();

    // let params = {
    // 	'search': this.state.search,
    // };

    // const url = this.state.url.search;

    this.setState({ loading: true });

    this.retrieveData("", "");
    // Services.submitRequest({ params, url }).then( res => {
    //     this.setState({ loading: false });
    // }).catch( res => {
    //     this.setState({ errors: res, loading: false });
    // });
  };

  getRedirect = () => {
    if (this.state.redirect_url !== "") {
      return <Redirect to={this.state.redirect_url} />;
    }

    return "";
  };

  onNavbarClick = (url, category_id = "") => {
    this.props.history.push(url);

    this.retrieveData(category_id, "");
  };

  getNavbarItems = () => {
    const { category_id, subcategory_id, category_name, subcategory_name } = {
      ...this.state,
    };
    let navbar_items = [];

    navbar_items.push({
      name: "หนัาหลัก",
      onClick: () => this.onNavbarClick("/publishdocument"),
    });

    if (category_id) {
      navbar_items.push({
        name: category_name,
        onClick: () =>
          this.onNavbarClick(`/publishdocument/${category_id}`, category_id),
      });

      if (subcategory_id) {
        navbar_items.push({ name: subcategory_name });
      }
    }

    return navbar_items.map((item, key) => {
      if (key === navbar_items.length - 1) {
        return (
          <li key={key} className="breadcrumb-item active">
            {item.name}
          </li>
        );
      } else {
        return (
          <li key={key} className="breadcrumb-item">
            <a href="javascript:void(0)" onClick={item.onClick}>
              {item.name}
            </a>
          </li>
        );
      }
    });
  };

  render() {
    const navbar_items = this.getNavbarItems();
    const { category_id } = { ...this.state };
    let btn_back;

    if (category_id !== "") {
      btn_back = (
        <a
          className="btn btn-lg btn-block btn-seconday mt-3"
          onClick={this.onClickBack}
        >
          ย้อนกลับ
        </a>
      );
    }

    let data;
    if (
      this.state.category_id === "" &&
      this.state.data.some((f) => f.is_folder === true)
    ) {
      data = this.state.data.map((item, key) => {
        return (
          <PublishFolder
            index={key}
            key={key}
            name={item.name}
            id={item.id}
            onClick={this.onClick}
            publishes_count={item.publishes_count}
          ></PublishFolder>
        );
      });

      data = <ul className="list-group col-12">{data}</ul>;
    } else {
      data = this.state.data.map((item, key) => {
        return (
          <PublishFile
            key={key}
            id={item.id}
            name={item.name}
            title={item.title}
            download_amount={item.download_amount}
            link={item.link}
            icon={item.icon}
            category_name={item.category_name}
            subcategory_name={item.subcategory_name}
            index={key}
            is_folder={item.is_folder}
            onClick={this.onClick}
          ></PublishFile>
        );
      });

      data = (
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>ชื่อ</th>
                <th>จำนวนดาวน์โหลด</th>
                <th>ดาวน์โหลด</th>
              </tr>
            </thead>
            <tbody>{data}</tbody>
          </table>
        </div>
      );
    }

    if (this.state.data.length === 0) {
      data = <div className="text-center">ไม่มีข้อมูล</div>;
    }

    const redirect = this.getRedirect();

    return (
      <FrontendBoxContainer
        loading={this.state.loading}
        title="เอกสารเผยแพร่"
        errors={this.state.errors}
      >
        {redirect}
        <div className="bg-white p-5">
          <div className="card">
            <div className="card-header text-left">ค้นหา</div>
            <div className="card-body">
              <form className="text-left" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ชื่อเอกสาร หัวข้อเอกสาร"
                      id="search"
                      name="search"
                      onChange={this.onChange}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="basic-addon2">
                        <i className="fa fa-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  ค้นหา
                </button>
              </form>
            </div>
          </div>
          <div className="mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb" style={{ background: "none" }}>
                {navbar_items}
              </ol>
            </nav>
            <div className="card-body text-left pt-0">
              <div className="row">{data}</div>

              {btn_back}
            </div>
          </div>
        </div>
      </FrontendBoxContainer>
    );
  }
}

export default Publish;
