import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import defaultLogo from '../../../assets/images/default_logo.png';

var HtmlToReactParser = require('html-to-react').Parser;

class KeynoteSpeaker extends Component {
  state = {
    redirect: false,
    loading: false,
    errors: [],
    data: { },
    datasource: { }
  }

  onChange = e => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value
    }

    this.setState({
      data
    });
  }

  componentDidMount = () => { 

  }

  render() {
    const data = this.props.data.map( (item, key) => {
      var thumbnail = defaultLogo;

      if(item.avatar_url){
        thumbnail = item.avatar_url;
      }

      return (
        <div className="col-md-4 col-lg-3" key={key}>
          <a href="#" className="media-1 text-center">
            <img src={thumbnail} alt="Image" className="img-fluid mx-auto" />
            <div className="media-1-content">
              <h2>{item.name}</h2>
              <span className="category">{item.company_name}</span>
            </div>
          </a>
        </div>
      )
    })

    return (
      <section className="site-section">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-8 text-center">
              <h2 className="text-black h1 site-section-heading text-center">วิทยากรผู้ทรงคุณวุฒิ</h2>
              {/* <p className="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, itaque neque, delectus odio iure explicabo.</p> */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            {data}
          </div>
        </div>
      </section>
    )
  }
}

export default KeynoteSpeaker