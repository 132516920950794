import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../../axios';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { BrowserRouter, Route } from 'react-router-dom';
import bg_hero from '../../../assets/images/hero_1.jpg'
import { Services, Auth, Alert } from '../../Service/Services';
import FrontendBoxContainer from './../../../components/FrontendBoxContainer/FrontendBoxContainer';
import FileInput from '../../../components/FileInput/FileInput';
import BAY from '../../../assets/images/banks-logo/bay.svg';
import BBL from '../../../assets/images/banks-logo/bbl.svg';
import KBANK from '../../../assets/images/banks-logo/kbank.svg';
import KTB from '../../../assets/images/banks-logo/ktb.svg';
import SCB from '../../../assets/images/banks-logo/scb.svg';
import TMB from '../../../assets/images/banks-logo/tmb.svg';
import UOB from '../../../assets/images/banks-logo/uob.svg';
const $ = window.$;


var HtmlToReactParser = require('html-to-react').Parser;

class MemberCancelPayment extends Component {
  state = {
    redirect: false,
    datasource: {
		bank: [],
	},
	loading: true,
	data: {
		bank_id: '',
		bank_account_no: '',
		bank_account_name: '',
		bank_account_branch: '',
		bank_account_type: '',
		book_bank_asset_file: '',
		book_bank_asset_file_preview: '',
		cancel_date: '',
		members: [],
	},
	url: {
		cancel_payment: 'member/cancel'
	},
	errors: [],
  }

  init = () => {
    const promise = new Promise((resolve) => {
      const params = {
        tables: [
          {name: 'bank'},
        ],
      }
  
      axios.post('datasource', params).then(res => {
        const bank = res.data.bank.map( item => {
          return {
            key: item.id,
            label: item.name,
            code: item.code,
          };
        });
  
        const datasource = {
          bank,
        };

        resolve(datasource);
      });
    });

    return promise;
  }

  retrieveData = (id, datasource) => {
    axios.get(`member/${id}`).then(res => {
      const data = res.data;
      let bank_account_no = data.bank_account_no;
      
      if(bank_account_no && bank_account_no.length > 0){
        bank_account_no = bank_account_no.substring(0, 3) + '-' + bank_account_no.substring(3, 6) + '-' + bank_account_no.substring(6, 10);
      }

      console.log(bank_account_no, data.bank_account_no);

      const new_data = {
        bank_id: (data.bank_id || ''),
    		bank_account_no: (bank_account_no || ''),
    		bank_account_name: (data.bank_account_name || ''),
    		bank_account_branch: (data.bank_account_branch || ''),
    		bank_account_type: (data.bank_account_type || ''),
    		book_bank_asset_file: (data.book_bank_asset_file || ''),
    		book_bank_asset_file_preview: (data.book_bank_asset_file_preview || ''),
    		cancel_date: data.cancel_date,
    		members: data.members.map( (item, key) => {
          const prefix = (item.prefix_id === null ? item.prefix_text : item.prefix_id);

          return {
            prefix_id: prefix,
            first_name: item.first_name,
            last_name: item.last_name,
            identity_number: (item.identity_number ? item.identity_number : ''),
          }
        }),
      }

      this.setState({ data: new_data, loading: false, datasource });
    });
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;

    this.init().then( datasource => {
      this.retrieveData(id, datasource);
    });
  }

  onChange = e => {
		const { name, value } = e.target;

    console.log(name, value);

		const data = {
            ...this.state.data,
			[name]: value
		}

		this.setState({
			data
		});
	}

	onFileChange = e => {
	    const { name, files } = e.target;
	    const data = {
	      ...this.state.data,
	      [name]: files[0],
	    }

	    this.setState({
	      data
	    });
	  }

	  onSubmit = (e) => {
	  	e.preventDefault();
	  	Alert.confirm('คุณแน่ใจไหมที่จะขอคืนเงินค่าลงทะเบียน').then( res => {
	  		if(res === true){
			    let params = { ...this.state.data };
          params.bank_account_no = params.bank_account_no.replace(/-/gi,'');

			    const id = this.props.match.params.id;
			    const url = this.state.url.cancel_payment + '/' + id;
			    const redirect = true;
			    let loading = true;

			    this.setState({ loading });

			    Services.submitRequestWithFile({ params, url }).then( res => { this.setState({redirect, loading:false}) }).catch( errors => {this.setState({ errors,loading:false })});
	  		}
	  	});
	  }

	  getBankLogo = code => {
	  	let img = '';

	  	switch(code){
		  	case 'BAY': img = BAY; break;
			case 'BBL': img = BBL; break;
			case 'KBANK': img = KBANK; break;
			case 'KTB': img = KTB; break;
			case 'SCB': img = SCB; break;
			case 'TMB': img = TMB; break;
			case 'UOB': img = UOB; break;
		}

		return <img className={'img-bank ' + code} src={img} />
	  }

  getRedirect = () => {
    const redirect = this.state.redirect;

    if (redirect) {
      const url = '/member';

      return <Redirect to={url} />;
    }

    return;
  }

  render() {
    let error;

    if (this.state.errors.length > 0) {
      let errors = this.state.errors.map((e, key) => <li key={key}><i className='fa fa-close'></i> {e}</li>)

      error = <div className='alert alert-danger text-left'><ul className='list-unstyled'>{errors}</ul></div>
    }

    const redirect = this.getRedirect();
    const disabled = (this.state.data.cancel_date !== null);
    const id = this.props.match.params.id;
    const bank_list_elements = this.state.datasource.bank.map( (bank, key) => {
    	const value = bank.key;
    	const id = ("bank_id_" + value);
    	const bank_logo = this.getBankLogo(bank.code.toUpperCase());
    	const checked = (parseInt(this.state.data.bank_id) === value);

    	return (
	    	<div className="form-check" key={key}>
			  <input checked={checked} disabled={disabled} required className="form-check-input" type="radio" name="bank_id" id={id} value={value} onChange={this.onChange} />
			  <label className="form-check-label" htmlFor={id}>
			    {bank_logo} {bank.label}
			  </label>
			</div>
    	)
    });

    let btn_submit;

    if(disabled === false){
    	btn_submit = (<button type="submit" className="btn btn-primary">ขอคืนเงินค่าลงทะเบียน</button>);
    }

    const members = this.state.data.members.map( (item, key) => {
		return (
			<tr key={key}>
			  <td>{item.item} {item.first_name} {item.last_name}</td>
			  <td className='text-center'>{item.identity_number}</td>
			</tr>
		)
	})

    return (
      <FrontendBoxContainer 
        loading={this.state.loading}
        title='ขอคืนเงินค่าลงทะเบียนเข้าร่วมงานประชุม'
        subtitle=''
      >
        {redirect}
        {error}
        <div className='text-left'>
	        <p>
			  <a className="btn btn-primary" data-toggle="collapse" href="#members" role="button" aria-expanded="false" aria-controls="members">
			    <i className='fa fa-users'></i> ผู้เข้าร่วมประชุม
			  </a>
			</p>
			<div className="collapse" id="members">
			  <div className="card card-body">
			    <table className='table w-100'>
	                <thead>
	                    <tr>
	                      <th className='text-center'>ชื่อ - นามสกุล</th>
	                      <th className='text-center'>เลขที่บัตรประชาชน</th>
	                    </tr>
	                </thead>
	                <tbody>
	                    {members}
	                </tbody>
	            </table>
			  </div>
			</div>
		</div>
		<div className='alert alert-info mb-3'>ข้อมูลบัญชีสำหรับโอนเงินคืน</div>
        <form onSubmit={this.onSubmit} className='text-left'>
          	<div className="row form-group">
              <div className="col-md-12">
                <label className="field-required text-black" htmlFor="bank_id">บัญชีธนาคาร:</label>
                {bank_list_elements}
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <label className="field-required text-black" htmlFor="bank_account_no">เลขที่บัญชี:</label>
                <input disabled={disabled} required type='text' minLength='10' data-mask="999-999-9999" name='bank_account_no' id='bank_account_no' onBlur={this.onChange} onChange={this.onChange} value={this.state.data.bank_account_no} className='form-control input-mask' />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <label className="field-required text-black" htmlFor="bank_account_name">ชื่อบัญชี:</label>
                <input disabled={disabled} required type='text' name='bank_account_name' id='bank_account_name' onChange={this.onChange} value={this.state.data.bank_account_name} className='form-control' />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <label className="text-black" htmlFor="bank_account_branch">สาขา:</label>
                <input disabled={disabled} type='text' name='bank_account_branch' id='bank_account_branch' onChange={this.onChange} value={this.state.data.bank_account_branch} className='form-control' />
              </div>
            </div>

            <div className="row form-group">
              <div className="col-md-12">
                <label className="text-black" htmlFor="bank_account_type">ประเภท:</label>
                <input disabled={disabled} type='text' name='bank_account_type' id='bank_account_type' onChange={this.onChange} value={this.state.data.bank_account_type} className='form-control' />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-12">
                <label className="field-required text-black" htmlFor="book_bank_asset_file">หน้า Book Bank:</label>
                <FileInput accept='image/x-png,image/gif,image/jpeg' disabled={disabled} multiple={false} name='book_bank_asset_file' value={this.state.data.book_bank_asset_file_preview} required={true} onChange={this.onFileChange} ></FileInput>
              </div>
            </div>
            <div className="row form-group text-center">
	        	<a href='/member' className="btn btn-secondary mx-2">ย้อนกลับ</a>
	        	{btn_submit}
	        </div>
	    </form>
      </FrontendBoxContainer>
    )
  }
}

export default MemberCancelPayment