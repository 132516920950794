import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import Dropdown from "../../../components/Dropdown/Dropdown";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
import { Services, Alert } from "../../Service/Services";
const $ = window.$;

class Profile extends Component {
  state = {
    redirect: false,
    errors: [],
    loading: true,
    data: {
      id: "",
      company_id: "",
      company_text: "",
      telephone: "",
      username: "",
      email: "",
      company_address: "",
      company_sub_district: "",
      company_district: "",
      company_province_id: "",
      company_zip_code: "",
      company_tax_identity_no: "",
      name: "",
    },
    datasource: {
      province: [],
    },
    url: {
      get: "/auth/user/getprofile",
      post: "/auth/user/changeprofile",
    },
  };

  onChange = (e) => {
    const { name, value } = e.target;

    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  onDDLChange = (obj) => {
    let data = { ...this.state.data, ...obj };
    this.setState({ data });
  };

  retrieveData = (datasource) => {
    axios.get(this.state.url.get).then((res) => {
      const data = res.data;
      const new_data = {
        id: data.id,
        company_id: data.company_id || data.company_text,
        company_text: data.company_text,
        telephone: data.telephone,
        username: data.username,
        email: data.email,
        company_address: data.company_address,
        company_sub_district: data.company_sub_district,
        company_district: data.company_district,
        company_province_id: data.company_province_id,
        company_zip_code: data.company_zip_code,
        company_tax_identity_no: data.company_tax_identity_no,
        name: data.name,
      };

      this.setState({ data: new_data, datasource, loading: false });
    });
  };

  componentDidMount = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "province" }],
    };

    axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          province: res.data.province.map((item) => {
            return { key: item.id, label: item.name };
          }),
        };

        this.retrieveData(datasource);
      })
      .catch((res) => {
        console.log("error", res);
      });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let params = this.state.data;
    const url = this.state.url.post;

    this.setState({ loading: true });

    Services.submitRequest({ params, url })
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((res) => {
        this.setState({ errors: res, loading: false });
      });
  };

  render() {
    let register_btn = (
      <button className="btn btn-lg btn-block btn-primary">บันทึกข้อมูล</button>
    );

    const company_id = this.state.data.company_id;
    let company_address = "";

    if (company_id !== "" && isNaN(parseInt(company_id)) === true) {
      company_address = (
        <div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_tax_identity_no"
              >
                หมายเลขประจำตัวผู้เสียภาษี
              </label>
              <input
                required
                type="text"
                id="company_tax_identity_no"
                onChange={this.onChange}
                name="company_tax_identity_no"
                value={this.state.data.company_tax_identity_no}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_address"
              >
                ที่อยู่
              </label>
              <input
                required
                type="text"
                id="company_address"
                onChange={this.onChange}
                name="company_address"
                value={this.state.data.company_address}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_sub_district"
              >
                ตำบล
              </label>
              <input
                required
                type="text"
                id="company_sub_district"
                onChange={this.onChange}
                name="company_sub_district"
                value={this.state.data.company_sub_district}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_district"
              >
                อำเภอ
              </label>
              <input
                required
                type="text"
                id="company_district"
                onChange={this.onChange}
                name="company_district"
                value={this.state.data.company_district}
                className="form-control"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_province_id"
              >
                จังหวัด
              </label>
              <Dropdown
                datasource={this.state.datasource.province}
                name="company_province_id"
                value={this.state.data.company_province_id}
                onChange={this.onDDLChange}
                required={true}
                placeholder="กรุณาเลือก"
              ></Dropdown>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-12 mb-3 mb-md-0">
              <label
                className="text-black field-required"
                htmlFor="company_zip_code"
              >
                รหัสไปรษณีย์
              </label>
              <input
                required
                type="text"
                id="company_zip_code"
                onChange={this.onChange}
                name="company_zip_code"
                value={this.state.data.company_zip_code}
                className="form-control"
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <FrontendBoxContainer
        loading={this.state.loading}
        title="ประวัติส่วนตัว"
        errors={this.state.errors}
      >
        <form
          action="#"
          className="p-5 bg-white text-left"
          onSubmit={this.onSubmit}
        >
          <h2 className="h4 text-black mb-5 text-center">บัญชีผู้ใช้งาน</h2>

          <div className="card">
            <div className="card-header bg-info text-white">ข้อมูลสถาบัน</div>
            <div className="card-body">
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label
                    className="text-black field-required"
                    htmlFor="company_id"
                  >
                    ชื่อสถาบัน/ ช่วยหน่วยงาน / ชื่อโรงพยาบาล
                  </label>
                  <Dropdown
                    name="company_id"
                    value={this.state.data.company_id}
                    text={this.state.data.company_text}
                    onChange={this.onDDLChange}
                    allow_new_item={true}
                    required={true}
                    placeholder="กรุณาเลือก"
                    ajax={true}
                    api_url={"get/company"}
                  ></Dropdown>
                  <small className="text-muted">
                    พิมพ์อย่างน้อย 2 ตัวอักษรเพื่อค้นหา
                    หากไม่พบสามารถพิมพ์ชื่อหน่วยงาน ที่ถูกต้องและกดเลือก
                  </small>
                </div>
              </div>
              {company_address}
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-header bg-secondary text-white">
              ข้อมูลบัญชี
            </div>
            <div className="card-body">
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="field-required text-black" htmlFor="name">
                    ชื่อ - นามสกุล
                  </label>
                  <input
                    required
                    type="text"
                    id="name"
                    onChange={this.onChange}
                    name="name"
                    value={this.state.data.name}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label
                    className="field-required text-black"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <input
                    required
                    type="text"
                    id="username"
                    onChange={this.onChange}
                    name="username"
                    value={this.state.data.username}
                    className="form-control"
                  />
                  <small className="text-mute">
                    Username จะต้องเป็นภาษาอังกฤษและตัวเลขเท่านั้น ไม่มีช่องว่าง
                  </small>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="text-black" htmlFor="email">
                    อีเมล: {this.state.data.email}
                  </label>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12 mb-3 mb-md-0">
                  <label
                    className="field-required text-black"
                    htmlFor="telephone"
                  >
                    เบอร์โทรศัพท์
                  </label>
                  <input
                    maxLength="10"
                    required
                    type="text"
                    id="telephone"
                    onChange={this.onChange}
                    name="telephone"
                    value={this.state.data.telephone}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          {register_btn}
        </form>
      </FrontendBoxContainer>
    );
  }
}

export default Profile;
