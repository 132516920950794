import React, { useState } from "react";
import { BrowserRouter, Route, useParams } from "react-router-dom";
import FrontendBoxContainer from "./../../../components/FrontendBoxContainer/FrontendBoxContainer";
import agreementData from "../../../data/agreement";
import axios from "../../../axios";
import swal from "sweetalert";

const Agreement = ({}) => {
  const { type, earlybird } = useParams();
  const [acceptAgreement, setAcceptAgreement] = useState(false);
  const [acceptNews, setAcceptNews] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const redirect = () => {
    let redirectUrl = "/";
    if (type === "online") {
      redirectUrl = `/member/${earlybird ? "earlybird/" : ""}formonline`;
    } else {
      redirectUrl = `/member/${earlybird ? "earlybird/" : ""}form`;
    }

    if (acceptNews === true) redirectUrl += `?news`;

    window.location.href = redirectUrl;
  };

  const onSubmit = () => {
    redirect();
  };

  return (
    <BrowserRouter>
      <FrontendBoxContainer
        loading={isLoading}
        subtitle="Agreement"
        title="คำประกาศเกี่ยวกับความเป็นส่วนตัว"
      >
        <div className="bg-white main-form text-left">
          <div className="row form-group">
            <div className="col-md-12 mb-3">
              <div
                style={{
                  overflowY: "scroll",
                  height: "500px",
                  backgroundColor: "#e6e6e6",
                }}
                className="p-4"
              >
                {agreementData}
              </div>
            </div>
            <div className="col-md-12 mb-3 mb-md-0 pl-5">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="acceptAgreement"
                  onChange={(e) => {
                    setAcceptAgreement(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="acceptAgreement">
                  ข้าพเจ้าได้อ่านและยินยอมให้ผูกพันตามเงื่อนไขภายใต้ข้อตกลงการให้บริการนี้
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="acceptNews"
                  onChange={(e) => {
                    setAcceptNews(e.target.checked);
                  }}
                />
                <label className="form-check-label" htmlFor="acceptNews">
                  ยินยอมให้แจ้งข่าวสารผ่านทางช่องทางอีเมล
                </label>
              </div>
            </div>
            <div className="text-center w-100 mt-2 mb-5">
              <button
                onClick={onSubmit}
                className="btn btn-primary mr-2 px-5"
                disabled={acceptAgreement === false}
              >
                ตกลง
              </button>
              <a href="/member" className="btn btn-danger px-5">
                ย้อนกลับ
              </a>
            </div>
          </div>
        </div>
      </FrontendBoxContainer>
    </BrowserRouter>
  );
};

export default Agreement;
