import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
var HtmlToReactParser = require('html-to-react').Parser;

class Agenda extends Component {
  state = {
    redirect: false,
    loading: false,
    errors: [],
    data: { },
    datasource: { }
  }

  onChange = e => {
    const { name, value } = e.target;
    const data = {
      ...this.state.data,
      [name]: value
    }

    this.setState({
      data
    });
  }

  componentDidMount = () => { 

  }

  render() {
    return (
      <section className="site-section">
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-8 text-center">
              <h2 className="text-black h1 site-section-heading text-center">รายละเอียดกำหนดการ</h2>
              <p className="lead">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores, itaque neque, delectus odio iure explicabo.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="p-3 box-with-humber">
                <div className="number-behind">01.</div>
                <h2>วันที่ 9 กันยายน 2561</h2>
                <ul className=''>
                  <li>09:00 - 10:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                  <li>10:00 - 11:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                  <li>11:00 - 12:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="p-3 box-with-humber">
                <div className="number-behind">02.</div>
                <h2>วันที่ 10 กันยายน 2561</h2>
                <ul className=''>
                  <li>09:00 - 10:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                  <li>10:00 - 11:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                  <li>11:00 - 12:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="p-3 box-with-humber">
                <div className="number-behind">03.</div>
                <h2>วันที่ 11 กันยายน 2561</h2>
                <ul className=''>
                  <li>09:00 - 10:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                  <li>10:00 - 11:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                  <li>11:00 - 12:00 ระบบส่งยาทางไปรษณีย์ ห้อง Sapphire 202 วิทยากร คุณเอ</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Agenda