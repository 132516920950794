import React, { Component } from 'react'
import swal from 'sweetalert';
import axios from '../../../axios';
import { Redirect } from 'react-router-dom';
import bg_hero from '../../../assets/images/hero_1.jpg';
import FileInput from '../../../components/FileInput/FileInput';
import MemberFormStep1 from './MemberFormStep1';
import MemberFormStep2 from './MemberFormStep2';
import MemberFormStep3 from './MemberFormStep3';
import TagManager from 'react-gtm-module'
import { Alert } from '../../Service/Services';
const $ = window.$;

class MemberDone extends Component {
    constructor(props) {
        super(props);

        this.countdown = React.createRef();
    }

	state = {
		data: {
			members: [],
		},
		selected_member: null,
	}

	componentDidMount = () => {
        const tagManagerArgs = {
            gtmId: 'GTM-5Z88NG8'
        }
        
        TagManager.initialize(tagManagerArgs)
        
        const $countdown = $(this.countdown.current);
        let second = 10;
        $countdown.text(second);

        setInterval(() => {
            second--;
            
            $countdown.text(second);

            if(second <= 1){ 
                document.location.href = '/member';
            };
        }, 1000);
	}

  	render() {
	    return (
            <div>
            <div className="site-blocks-cover inner-page-cover overlay" style={{'backgroundImage': `url(${bg_hero})`}} data-aos="fade" data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">
                            <div className="row justify-content-center mb-4">
                                <div className="col-md-8 text-center">
                                    <h1>ลงทะเบียนเข้าร่วมงาน</h1>
                                    <p className="lead mb-5">ลงทะเบียนผู้ที่เข้าร่วมงานประชุม</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 


            <section className="site-section bg-light">
              <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <h1>การสมัครสมาชิกเสร็จสมบูรณ์</h1>
                        <p>ระบบจะกลับไปยังหน้าหลักภายใน <span ref={this.countdown}></span> วินาที หรือ <u><a href='/member'>คลิ๊กที่นี้</a></u></p>
                    </div>
                </div>
              </div>
            </section>
        </div>
    	)
  	}
}

export default MemberDone