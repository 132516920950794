import React, { Component } from "react";
import iconFolder from "../../../assets/images/icons-folder.png";

class PublishFolder extends Component {
  state = {};

  onClick = (id) => {
    this.props.onClick(id);
  };

  render() {
    const { id, name, index, is_folder, publishes_count = 0 } = this.props;
    const delay = index * 100;

    return (
      <>
        <li className="list-group-item cursor-pointer">
          <i className="fa fa-folder"></i>&nbsp;
          <a className="text-link btn-link" onClick={(e) => this.onClick(id)}>
            {name}
          </a>
          &nbsp;
          <span className="badge badge-primary badge-pill pull-right">
            {publishes_count} ไฟล์
          </span>
        </li>
      </>

      // <div className="col-md-3 col-6 mb-4 cursor-pointer" data-aos="fade-up" data-aos-delay={delay}>
      //     <a onClick={e => this.onClick(id)}>
      //         <img src={iconFolder} alt={name} className='img-fluid mx-auto' />
      //         <span>{name}</span>
      //     </a>
      // </div>
    );
  }
}

PublishFolder.defaultProps = {
  name: "",
  id: "",
  link: "",
  index: 0,
  onClick: {},
};

export default PublishFolder;
