import React, { Component } from "react";
import swal from "sweetalert";
import axios from "../../../axios";
import { Redirect } from "react-router-dom";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Avatar from "../../../components/Avatar/Avatar";
import { Services } from "../../Service/Services";
const $ = window.$;

class MemberFormOnlinePopup extends Component {
  constructor(props) {
    super(props);

    this.modal = React.createRef();
  }

  state = {
    data: {
      id: "",
      email: "",
      prefix_id: "",
      first_name: "",
      last_name: "",
      identity_number: "",
      career_id: "",
      career_number: "",
      nurse_number: "",
      row_index: "",
      avatar: "",
    },
    datasource: {
      prefix_name: [],
      career: [],
    },
    nurse_code: "C0001",
    errors: [],
    loading: false,
  };

  onChange = (e) => {
    const { name, value } = e.target;

    const data = {
      ...this.state.data,
      [name]: value,
    };

    this.setState({
      data,
    });
  };

  onDDLChange = (obj) => {
    let data = { ...this.state.data, ...obj };
    this.setState({ data });
  };

  onAvatarChange = (value) => {
    const data = {
      ...this.state.data,
      ...value,
    };

    this.setState({ data });
  };

  getEmptyData = () => {
    return {
      id: "",
      avatar: "",
      email: "",
      prefix_id: "",
      first_name: "",
      last_name: "",
      identity_number: "",
      career_id: "",
      career_number: "",
      nurse_number: "",
      row_index: "",
      avatar: "",
    };
  };

  getAPIDatasource = () => {
    const api_url = "datasource";
    const params = {
      tables: [{ name: "career" }, { name: "prefix_name" }],
    };

    const promise = axios
      .post(api_url, params)
      .then((res) => {
        const datasource = {
          career: res.data.career.map((item) => {
            return { key: item.id, label: item.name, code: item.code };
          }),
          prefix_name: res.data.prefix_name.map((item) => {
            return { key: item.id, label: item.name };
          }),
        };

        return datasource;
      })
      .catch((res) => {
        console.log("error", res);
      });

    return promise;
  };

  onSubmit = (e) => {
    e.preventDefault();

    let data = { ...this.state.data };

    const is_nurse =
      this.state.datasource.career.find(
        (f) => f.key == data.career_id && f.code === this.state.nurse_code
      ) !== undefined;

    if (is_nurse === true && data.nurse_number.trim() === "") {
      const errors = ["กรุณากรอกเลขที่พยาบาล"];

      this.setState({ errors });
    } else {
      this.props.onSubmitMember(data);

      $(this.modal.current).modal("hide");
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.member !== null) {
      //  && nextProps.member.row_index !== nextState.data.row_index
      let member = nextProps.member;

      this.setState({
        data: member,
      });
    }
  };

  componentDidMount = () => {
    $(this.modal.current).on("hidden.bs.modal", (e) => {
      const data = this.getEmptyData();
      this.setState({ data, errors: [] });
    });

    this.getAPIDatasource().then((result) => {
      this.setState({
        datasource: result,
      });
    });
  };

  render() {
    let save_button;
    const disabled = this.props.editable === false;

    if (this.props.editable === true) {
      save_button = (
        <button type="submit" className="btn btn-primary">
          บันทึก
        </button>
      );
    }

    let error_container;

    if (this.state.errors.length > 0) {
      const errors = this.state.errors.map((item, key) => (
        <li key={key}>
          <i className="fa fa-close"> {item}</i>
        </li>
      ));
      error_container = (
        <div className="alert alert-danger">
          <ul className="list-unstyled">{errors}</ul>
        </div>
      );
    }

    const is_nurse =
      this.state.datasource.career.find(
        (f) =>
          f.key == this.state.data.career_id && f.code === this.state.nurse_code
      ) !== undefined;

    let nurse_number;

    if (is_nurse) {
      nurse_number = (
        <div className="row form-group">
          <div className="col-md-12 mb-3 mb-md-0">
            <label className="text-black" htmlFor="nurse_number">
              เลขสภาการพยาบาล:
            </label>
            <input
              type="text"
              name="nurse_number"
              id="nurse_number"
              onChange={this.onChange}
              disabled={disabled}
              value={this.state.data.nurse_number}
              className="form-control"
            />
            <small className="text-muted">
              ถ้าอาชีพเป็นพยาบาล จะต้องกรอก เลขสภาการพยาบาล
            </small>
          </div>
        </div>
      );
    }

    // console.log('render', this.state.data);

    return (
      <div
        ref={this.modal}
        className="modal fade"
        id="memberModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header bg-primary  text-white">
              <h5 className="modal-title" id="exampleModalLabel">
                ข้อมูลสมาชิก
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              action="#"
              className="px-5 bg-white text-left"
              onSubmit={this.onSubmit}
            >
              <div className="modal-body">
                {error_container}
                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="avatar">
                      รูปภาพ:
                    </label>
                    <br />
                    <Avatar
                      required={false}
                      value={this.state.data.avatar}
                      name="avatar"
                      onChange={this.onAvatarChange}
                      multiple={false}
                      disabled={false}
                    ></Avatar>
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label
                      className="field-required text-black"
                      htmlFor="prefix_id"
                    >
                      คำนำหน้า:
                    </label>
                    <Dropdown
                      datasource={this.state.datasource.prefix_name}
                      name="prefix_id"
                      value={this.state.data.prefix_id}
                      onChange={this.onDDLChange}
                      allow_new_item={true}
                      required={true}
                      disabled={disabled}
                      placeholder="กรุณาเลือก"
                    ></Dropdown>
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label
                      className="field-required text-black"
                      htmlFor="first_name"
                    >
                      ชื่อ:
                    </label>
                    <input
                      required
                      type="text"
                      name="first_name"
                      id="first_name"
                      onChange={this.onChange}
                      disabled={disabled}
                      value={this.state.data.first_name}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label
                      className="field-required text-black"
                      htmlFor="last_name"
                    >
                      นามสกุล:
                    </label>
                    <input
                      required
                      type="text"
                      name="last_name"
                      id="last_name"
                      onChange={this.onChange}
                      disabled={disabled}
                      value={this.state.data.last_name}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label
                      className="field-required text-black"
                      htmlFor="email"
                    >
                      อีเมล:
                    </label>
                    <input
                      required
                      type="text"
                      name="email"
                      id="email"
                      onChange={this.onChange}
                      disabled={disabled}
                      value={this.state.data.email}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="identity_number">
                      รหัสบัตรประชาชน:
                    </label>
                    <input
                      type="text"
                      name="identity_number"
                      id="identity_number"
                      onChange={this.onChange}
                      disabled={disabled}
                      value={this.state.data.identity_number}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label
                      className="field-required text-black"
                      htmlFor="career_id"
                    >
                      วิชาชีพ:
                    </label>
                    <Dropdown
                      datasource={this.state.datasource.career}
                      name="career_id"
                      value={this.state.data.career_id}
                      onChange={this.onDDLChange}
                      allow_new_item={true}
                      required={true}
                      disabled={disabled}
                      placeholder="กรุณาเลือก"
                    ></Dropdown>
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="career_number">
                      เลขที่ประกอบวิชาชีพ:
                    </label>
                    <input
                      type="text"
                      name="career_number"
                      id="career_number"
                      onChange={this.onChange}
                      disabled={disabled}
                      value={this.state.data.career_number}
                      className="form-control"
                    />
                  </div>
                </div>

                {nurse_number}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  ปิด
                </button>
                {save_button}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

MemberFormOnlinePopup.defaultProps = {
  editable: false,
};

export default MemberFormOnlinePopup;
